import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import Modal from 'react-modal';
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from '../Scroll/ScrollToTop';
import util from 'util';
import DocumentMeta from 'react-document-meta';
// import Popover from "react-popover";
// import CopyImg from '../Images/copy.svg';
import DigitalcardPersonImg from '../Images/digitalcard_person.svg';
import WhitePersonImg from '../Images/white_person.svg';
import DigitalcardTicketImg from '../Images/digitalcard_ticket.svg';
import WhiteTicketImg from '../Images/white_ticket.svg';
import CloseImg from '../Images/close.svg';
// import moment from 'moment';
// import AlertTicketImg from '../Images/alert_ticket.svg';
import Common from '../Common/common';
import CampaignIconImg from '../Images/campaign_icon.svg';
import WhiteCampaignImg from '../Images/white_campaign.svg';
import MembershipPassImg from '../Images/digitalcard_person_membership-pass.svg';
import WhiteMembershipPassImg from '../Images/white_membershippass.svg';
import ReceiptImg from '../Images/digitalcard_receipt.svg';
import WhiteReceiptImg from '../Images/white_receipt.svg';
import MembershipPass from './MembershipPass';
import TicketList from './TicketList';
import TicketIssueList from './TicketIssueList';
import Ticket from './Ticket';
import TicketIssue from './TicketIssue';
import ReceiptScan from './ReceiptScan';
import CouponList from './CouponList';
import Payment from '../Payment/Payment';
import Pointcard from './Pointcard'
import Liffconfirm from './Liffconfirm'
import moment from 'moment';
import CampaignEntry from './CampaignEntry';
import PostalCodeSearch from './PostalCodeSearch';

Modal.setAppElement("#root");

class Liff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      cssLoaded: false,
      my_order: null,
      ready: false,
      my_customer: {
        mail_address: '',
        gender: '',
        birth: '',
        postal_code: '',
        card_id: '',
        privacy_policy: constClass.FLAG.OFF,
        cancel_cause: '',
        m_coupon_code: []
      },
      original_customer: {},
      campaignlist: null,
      campaign: { campaign_id: '', m_site_card: [] },
      coupon_list: null,
      siteId: isFinite(props.match.params.siteId) ? props.match.params.siteId : 0,
      coupon_code: null,
      popover_message: {},
      message: {
        mail_address: '',
        gender: '',
        birth: '',
        postal_code: '',
        card_id: '',
        phone_number: '',
      },
      copy_message: null,
      user_change: false,
      modal_flag: false,
      pre_modal_flag: false,
      ticket_list: null,
      ticket_issuelist: null,
      ticket_all_sum_list: null,
      ticket_issue_all_sum_list: null,
      ng_coupon_list: [],
      my_entry: {
        campaign_id: '',
        status: constClass.STATUS.UREG,
        privacy_policy: constClass.FLAG.OFF
      },
      headerHeight: 0,
      footerHeight: 0,
      setting: null,
      initialQs: null,
      customer_detail_type: [],
      postal_code_flag: false,
      cancel_modal_flag: false,
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '10vw',
        bottom: 'auto',
        marginTop: '0.5rem',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.handleCssLoad = this.handleCssLoad.bind(this);
    this.getDefaultPage = this.getDefaultPage.bind(this);
    this.historyBack = this.historyBack.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.changeCustomer = this.changeCustomer.bind(this);
    this.changeCustomerDetail = this.changeCustomerDetail.bind(this);
    this.setReady = this.setReady.bind(this);
    this.copyTextToClipboard = this.copyTextToClipboard.bind(this);
    this.resetCustomer = this.resetCustomer.bind(this);
    this.returnPayment = this.returnPayment.bind(this);
    this.backPayment = this.backPayment.bind(this);
    this.cancelPayment = this.cancelPayment.bind(this);
    this.successPayment = this.successPayment.bind(this);
    this.closePostalCode = this.closePostalCode.bind(this);
    this.setPostalCode = this.setPostalCode.bind(this);
    this.pageRef = React.createRef();
    this.intervalPop = [];
    this.headerRef = React.createRef();
    this.footerRef = React.createRef();
  }

  handleCssLoad() {
    this.setState({ cssLoaded: true });
    this.setReady();
  };

  async componentDidMount() {
    // サイト情報取得
    var site = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/site/search/`, { site_id: this.state.siteId })).data;
    this.site_name = site.site_name;
    this.meta = {
      link: {
        rel: {
          icon: `/${this.state.siteId}/favicon.ico`,
          "apple-touch-icon": `/${this.state.siteId}/logo192.png`,
          manifest: `/${this.state.siteId}/manifest.json`,
          stylesheet: `/${this.state.siteId}/style.css`,
        },
      },
      title: site ? site.site_name : '',
      description: site ? site.site_name : '',
    }

    this.refreshData();
    this.disableBounceScroll();
    window.addEventListener('popstate', this.resetCustomer, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const height = { header: prevState.headerHeight, footer: prevState.footerHeight };
    if (this.headerRef.current) {
      const rect = this.headerRef.current.getBoundingClientRect();
      if (height.header !== rect.height) {
        this.setState({ headerHeight: rect.height });
      }
    }
    if (this.footerRef.current) {
      const rect = this.footerRef.current.getBoundingClientRect();
      if (height.footer !== rect.height) {
        this.setState({ footerHeight: rect.height });
      }
    }

    const linkElementNode = document.querySelector(`link[href="/${this.state.siteId}/style.css"]`);
    if (linkElementNode && !linkElementNode.onload) {
      linkElementNode.onload = this.handleCssLoad;
      linkElementNode.onerror = this.handleCssLoad;
    }
  }

  async componentWillUnmount() {
    // clearInterval(this.intervalCount);
    window.removeEventListener('popstate', this.resetCustomer, false);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      // let el = this.pageRef.current;// e.targetが機種によって変動するため、pageに固定
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
        el = el.parentElement;
      }
      // console.log(`moveY=${moveY}, touchY=${touchY}, offsetHeight=${el.offsetHeight}, scrollHeight=${el.scrollHeight}, scrollTop=${el.scrollTop}, className=${el.className}, id=${el.id}, ${el.localName}`);
      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  changeCustomer(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    const my_customer = { ...this.state.my_customer, [name]: value };
    this.setState({
      my_customer
    });

    var message = this.state.message;
    this.setState({
      message: { ...message, [name]: this.validator(name, value, this.state.setting) }
    });
    // console.log(`${name}: ${value}`);
  }

  changeCustomerDetail(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    const detail = [...this.state.my_customer.t_customer_detail];
    Common.setCustomerDetail(detail, name, value);
    const my_customer = { ...this.state.my_customer, t_customer_detail: detail };
    this.setState({
      my_customer
    });
  }

  validator(name, value, setting) {
    switch (name) {
      case 'birth':
        return this.dateValidation(value, setting);
      case 'postal_code':
        return this.postalCodeValidation(value, setting);
      case 'mail_address':
        return this.mailAddressValidation(value, setting);
      case 'card_id':
        return this.cardIdiValidation(value, setting);
      case 'phone_number':
        return this.phoneValidation(value, setting);
      default:
        return '';
    }
  }

  dateValidation(value, setting) {
    if (setting['INPUT_BIRTH'] === constClass.INPUT.ENABLED) return '';
    const date = Common.getDateObject(value);
    if (setting['INPUT_BIRTH_YEAR'] === constClass.FLAG.ON && !date.year) return '※年を選択してください';
    if (setting['INPUT_BIRTH_MONTH'] === constClass.FLAG.ON && !date.month) return '※月を選択してください';
    if (setting['INPUT_BIRTH_DATE'] === constClass.FLAG.ON && !date.date) return '※日を選択してください';
    return '';
  }

  postalCodeValidation(value, setting) {
    if (setting['INPUT_POSTAL_CODE'] === constClass.INPUT.ENABLED && value === '') return '';
    const regex = /^[0-9]{7}$/;
    if (!regex.test(value)) return '※正しい郵便番号を入力してください';
    // 郵便番号による地域チェック
    // const num = Number(value);
    // if (!(8000000 <= num && num <= 8399999) &&
    //   !(8710000 <= num && num <= 8710999) &&
    //   !(8400000 <= num && num <= 8499999) &&
    //   !(8640000 <= num && num <= 8649999) &&
    //   !(8610800 <= num && num <= 8610899) &&
    //   !(8770000 <= num && num <= 8779999) &&
    //   !(7500000 <= num && num <= 7599999)) return '※申し訳ございませんが、本サービスの提供エリア外のご登録となりますので、ご登録いただけません';
    return '';
  }

  mailAddressValidation(value, setting) {
    if (setting['INPUT_MAIL_ADDRESS'] === constClass.INPUT.ENABLED && value === '') return '';
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!regex.test(value)) return '※正しいメールアドレスを入力してください';
    return '';
  }

  cardIdiValidation(value, setting) {
    if (setting['INPUT_CARD_ID'] === constClass.INPUT.ENABLED && value === '') return '';
    const regex = /^[a-zA-Z0-9]{15}$/;
    if (!regex.test(value)) return '※正しい形式でnimoca番号を入力してください';
    return '';
  }

  phoneValidation(value, setting) {
    if (setting['INPUT_PHONE_NUMBER'] === constClass.INPUT.ENABLED && value === '') return '';
    const regex = /^\(?0[0-9\-()]{9,12}$/;
    if (!regex.test(value)) return '※正しい形式で電話番号を入力してください';
    return '';
  }

  scrollPageTop() {
    setTimeout(() => {
      this.pageRef.current.scrollTo(0, 0);
      // window.scrollTo(0, 0);
    }, 1);
  }

  scrollWindowTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  searchPostalCode() {
    this.setState({
      postal_code_flag: true
    });
  }

  closePostalCode() {
    this.setState({
      postal_code_flag: false
    });
  }

  setPostalCode(code) {
    const my_customer = { ...this.state.my_customer, postal_code: code };
    this.setState({
      my_customer,
      postal_code_flag: false
    });

    var message = this.state.message;
    this.setState({
      message: { ...message, postal_code: this.validator('postal_code', code, this.state.setting) }
    });
  }

  async assignCouponCode(my_customer, coupon_list) {
    // クーポンに払い出されていないものがあれば払い出す
    const used_list = my_customer.m_coupon_code.map(c => c.coupon_id);
    // クーポンリストから未使用のクーポンIDを抽出
    const unused_list = coupon_list.filter(c => !used_list.includes(c.coupon_id));
    // 未使用のクーポンがあれば払い出し
    if (unused_list.length > 0) {
      // 未使用のクーポンに空きがないリストを作成し使わせないようにする
      const ng_coupon_list = coupon_list.filter(c => unused_list.includes(c) && c.unused_code_count === 0).map(c => c.coupon_id);
      this.setState({ ng_coupon_list });
      // クーポンごとに払い出して結果を待ち合わせ
      await Promise.all(unused_list.filter(c => !ng_coupon_list.includes(c.coupon_id)).map(async (coupon) => {
        // 検索条件
        const params = {
          customer_id: my_customer.customer_id,
          coupon_month: Common.getMonthString(),
          line_id: this.props.liff_access_token
        };
        try {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/line/customer/${this.state.siteId}/${coupon.coupon_id}/`, params);
        } catch (err) {
          console.log(err);
          alert('エラーが発生しました。');
        }
      }));
      const customer_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, { site_id: this.state.siteId, line_id: this.props.liff_access_token })).data;
      my_customer = customer_data.find(c => c.line_id === this.props.liff_user_id);
    }
    return my_customer;
  }

  getDefaultPage(setting) {
    // 初期ページ設定
    var page;
    const s = setting ? setting : this.state.setting;
    if (Common.getFunctionEnabled(s, 'RECEIPTSCAN')) {
      page = constClass.RECEIPTSCAN
    } else if (Common.getFunctionEnabled(s, 'MEMBERCARD')) {
      page = constClass.MEMBERSHIPPASS
    } else if (Common.getFunctionEnabled(s, 'CAMPAIGN') && Common.getFunctionEnabled(s, 'CAMPAIGN_LIST')) {
      page = constClass.CAMPAIGNLIST;
    } else if (Common.getFunctionEnabled(s, 'COUPON')) {
      page = constClass.COUPONLIST;
    } else if (Common.getFunctionEnabled(s, 'TICKET')) {
      page = constClass.TICKETLIST;
    } else {
      page = constClass.USER;
    }
    return page;
  }

  historyBack() {
    if (this.props.history.length <= 1) {
      this.props.history.push(`/${this.state.siteId}/?page=${this.getDefaultPage(this.state.setting)}`);
    } else {
      this.props.history.goBack();
    }
  }

  historyBackTicketlist() {
    if (this.props.history.length <= 1) {
      this.props.history.push(`/${this.state.siteId}/?page=ticketlist`);
    } else {
      this.props.history.goBack();
    }
  }

  async refreshData(reload = false) {
    if (process.env.REACT_APP_ENV !== 'dev') {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalMaster) {
        this.intervalMaster = setInterval(() => {
          this.refreshData();
        }, 500);
      }
    } else {
      clearInterval(this.intervalMaster);
      if (!this.state.siteId) { // site未設定
        this.props.history.replace(`/1/?page=`); // site一覧からの設定処理？→暫定でリダイレクト
        return;
      }
      if (process.env.REACT_APP_ENV !== 'dev' && this.props.liff_access_token === 'token_unusable') { // トークン取得不可→非LINEブラウザ
        window.location.replace(process.env.REACT_APP_LINE_FRIEND); //友だち登録にリダイレクト
        return;
      }
      // 各種APIコール
      const params = { site_id: this.state.siteId, line_id: this.props.liff_access_token };
      const customerPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, params);
      const couponPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon/line/${this.state.siteId}/list/`, { line_id: this.props.liff_access_token });
      const settingPromise = axios.get(`${process.env.REACT_APP_BACKEND_URL}/setting/${this.state.siteId}`);
      const customerDetailPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customerdetail/line/${this.state.siteId}`, { line_id: this.props.liff_access_token });
      //設定取得
      const setting = (await settingPromise).data;
      //キャンペーン情報取得
      const campaignPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaign/line/${this.state.siteId}/list/`, { line_id: this.props.liff_access_token });
      /*
      if (!campaignId || campaignId === 0 || !campaign) {
        campaign = { campaign_id: '', m_site_card: [] };
        if ((!campaignId || campaignId === 0) && campaignlist.length > 0 && this.props.page !== constClass.CAMPAIGNLIST && this.props.page !== constClass.NOTCAMPAIGN) {
          this.props.history.replace(`/${this.state.siteId}?page=${constClass.CAMPAIGNLIST}`);
        } else {
          this.props.history.replace(`/${this.state.siteId}?page=${constClass.NOTCAMPAIGN}`);
        }
      } else {
        if (moment(campaign.campaign_close) <= moment()) {
          this.props.history.replace(`/${this.state.siteId}?page=${constClass.CAMPAIGNCLOSE}`);
        }
      }
      */

      const customer = (await customerPromise).data;
      var my_customer = customer.find(c => c.line_id === this.props.liff_user_id);
      if (!my_customer) {
        // データが無ければ起動時に登録
        var data = {
          'site_id': this.state.siteId,
          'line_id': this.props.liff_access_token,
          'status': constClass.STATUS.UREG
        };
        // API実行
        try {
          const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line`, data)).data;
          if (result.result) {
            my_customer = result.customer;
          } else {
            alert('データの登録に失敗しました。');
          }
        } catch (err) {
          console.log(err);
          alert('エラーが発生しました。');
        }
      }

      const initialQs = this.state.initialQs ? this.state.initialQs : {
        page: this.props.page,
        current_id: this.props.current_id,
        campaign_id: this.props.campaign_id,
        issue_count: this.props.issue_count,
      };
      // 未登録のユーザは必ず登録画面に遷移
      if (my_customer && [constClass.STATUS.UREG].includes(my_customer.status)) {
        // this.setState({ pre_modal_flag: true });
        this.props.history.replace(`/${this.state.siteId}/?page=`);
      } else if (my_customer && [constClass.STATUS.CCL].includes(my_customer.status)) {
        // this.setState({ pre_modal_flag: true });
        this.setState({ cancel_modal_flag: true });
        this.props.history.replace(`/${this.state.siteId}/?page=stop`);
      } else {
        // 登録済みでトップページ（登録画面）を開いた場合
        if (my_customer && my_customer.status === constClass.STATUS.REG && (this.props.page === undefined || this.props.page === null || this.props.page === '')) {
          if (initialQs.page === undefined || initialQs.page === null || initialQs.page === '') {
            this.props.history.replace(`/${this.state.siteId}/?page=${this.getDefaultPage(setting)}`);
          } else {
            var url = `/${this.state.siteId}/?page=${initialQs.page}`;
            if (initialQs.current_id) {
              url += `&current_id=${initialQs.current_id}`;
              this.props.setCurrentCoupon(initialQs.current_id);
            }
            if (initialQs.campaign_id) {
              url += `&campaign_id=${initialQs.campaign_id}`;
              this.props.setCampaignId(initialQs.campaign_id);
            }
            if (initialQs.issue_count) {
              url += `&issue_count=${initialQs.issue_count}`;
              this.props.setIssueCount(initialQs.issue_count);
            }
            this.props.history.replace(url);
          }
        }
        // 決済登録済みで決済画面を開いた場合
        if (my_customer && my_customer.status === constClass.STATUS.REG && [constClass.PAYMENT_STATUS.REG, constClass.PAYMENT_STATUS.REREG].includes(my_customer.payment_status) && this.props.page === constClass.PAYMENT) {
          this.props.history.replace(`/${this.state.siteId}/?page=${this.getDefaultPage()}`);
        }
      }

      const campaignId = (this.props.campaign_id ? this.props.campaign_id : initialQs.campaign_id);
      const campaignlist = (await campaignPromise).data;
      var campaign = campaignlist.find(x => x.campaign_id.toString() === campaignId.toString());
      var my_entry = this.state.my_entry;
      if (campaign && campaign.campaign_id) {
        my_entry = my_customer.t_entry.find(c => c.campaign_id.toString() === campaign.campaign_id.toString());
        if (!my_entry) {
          my_entry = {
            campaign_id: campaign.campaign_id,
            status: constClass.STATUS.UREG,
            privacy_policy: constClass.FLAG.OFF
          }
        }
      }

      const coupon_list = (await couponPromise).data;
      if (my_customer.status === constClass.STATUS.REG && setting[constClass.SETTING.PAYMENT_REQUIRED] === constClass.FLAG.ON) {
        // 決済のチェック 決済未登録・決済登録失敗　または　決済停止中かつ当月の決済無し　の場合は決済が必要
        if (!my_customer.payment_status || [constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.ERR].includes(my_customer.payment_status) ||
          (my_customer.payment_status === constClass.PAYMENT_STATUS.CCL && new Date(my_customer.last_payment_date).getMonth() < new Date().getMonth())) {
          if (!reload) {
            await this.submitPayment(my_customer);
            this.setState({ pre_modal_flag: true });
          }
        } else {
          my_customer = await this.assignCouponCode(my_customer, coupon_list);
        }
      } else if (my_customer.status === constClass.STATUS.REG) {
        my_customer = await this.assignCouponCode(my_customer, coupon_list);
      }
      const ticket_list = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/line/${this.state.siteId}/list/`, { customer_id: my_customer.customer_id, line_id: this.props.liff_access_token })).data;
      var ticket_all_sum_list = null;
      if(ticket_list.find(t => t.first_come_served && t.first_come_served > 0)){
        var ticket_id_list = ticket_list.filter(t => t.first_come_served && t.first_come_served > 0).map(t => t.ticket_id);
        ticket_all_sum_list = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_use/line/${this.state.siteId}/sumlist/`, { ticket_id_list: ticket_id_list, line_id: this.props.liff_access_token })).data;        
      }
      const ticket_issuelist = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/line/${this.state.siteId}/issuelist/`, { customer_id: my_customer.customer_id, line_id: this.props.liff_access_token })).data;
      var ticket_issue_all_sum_list = null;
      if(ticket_issuelist.find(t => t.first_come_served && t.first_come_served > 0)){
        var ticket_issue_id_list = ticket_issuelist.filter(t => t.first_come_served && t.first_come_served > 0).map(t => t.ticket_id);
        ticket_issue_all_sum_list = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/line/${this.state.siteId}/sumlist/`, { ticket_issue_id_list: ticket_issue_id_list, line_id: this.props.liff_access_token })).data;        
      }
      const customer_detail_type = (await customerDetailPromise).data;
      this.setState({
        my_customer: this.customerToState(my_customer, setting),
        original_customer: my_customer,
        coupon_list,
        ticket_list,
        ticket_issuelist,
        ticket_all_sum_list: ticket_all_sum_list ? ticket_all_sum_list : [],
        ticket_issue_all_sum_list: ticket_issue_all_sum_list ? ticket_issue_all_sum_list : [],
        setting,
        campaignlist: campaignlist ? campaignlist : [],
        campaign: campaign,
        my_entry: my_entry,
        initialQs,
        customer_detail_type
      });

      // // 登録済みのユーザはモーダル案内文を表示しない
      // if (my_customer && my_customer.status === constClass.STATUS.REG && 
      //   ([constClass.PAYMENT_STATUS.REG, constClass.PAYMENT_STATUS.REREG].includes(my_customer.payment_status) || (my_customer.payment_status === constClass.PAYMENT_STATUS.CCL && new Date(my_customer.last_payment_date).getMonth() === new Date().getMonth()))) {
      //   this.setState({ pre_modal_flag: false });
      // }

      this.setReady();
    }
  }

  async submitPayment(my_customer, change = false) {
    const payment_param = { site_id: this.state.siteId, line_id: this.props.liff_access_token };
    try {
      const payment_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/payment/${my_customer.customer_id}`, payment_param)).data;

      this.setState({ payment_data: { ...payment_data.data, change }, disabled: false });
    } catch (e) {
      console.log(e);
    } finally {
      this.props.setPage(constClass.PAYMENT);
      this.props.history.replace(`/${this.state.siteId}/?page=${constClass.PAYMENT}`);
    }
  }

  async successPayment() {
    try {
      const params = { site_id: this.state.siteId, line_id: this.props.liff_access_token };
      const customer = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/search/`, params)).data;
      var my_customer = customer.find(c => c.line_id === this.props.liff_user_id);
      my_customer = await this.assignCouponCode(my_customer, this.state.coupon_list);
      this.setState({ my_customer: this.customerToState(my_customer, this.state.setting), original_customer: my_customer });
    } catch (err) {
      console.log(err);
    }
  }

  async returnPayment() {
    if (this.state.payment_data.change) {
      this.props.history.replace(`/${this.state.siteId}/?page=${constClass.USER}`);
    } else {
      this.props.history.replace(`/${this.state.siteId}/?page=${constClass.COUPONLIST}`);
    }
    return true;
  }

  async backPayment() {
    if (this.state.payment_data.change) {
      this.props.history.replace(`/${this.state.siteId}/?page=${constClass.USER}`);
      return true;
    } else {
      if (this.state.setting[constClass.SETTING.PAYMENT_REQUIRED] === constClass.FLAG.ON) {
        this.setState({ modal_flag: true });
        return false;
      } else {
        await this.returnPayment();
        return true;
      }
    }
  }

  async cancelPayment() {
    if ([constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.ERR, constClass.PAYMENT_STATUS.CCL].includes(this.state.my_customer.payment_status)) {
      this.setState({ modal_flag: true });
    } else {
      if (!window.confirm('毎月のお支払いを停止してもよろしいですか？')) {
        return false;
      }
      try {
        // パラメータ作成
        const params = {
          site_id: this.state.siteId,
          payment_status: constClass.PAYMENT_STATUS.CCL,
          line_id: this.props.liff_access_token
        };

        // 中止処理実行
        const res = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/stop_payment/${this.state.my_customer.customer_id}`, params)).data;
        if (res.error) {
          window.alert('エラーが発生しました。' + res.message);
        } else {
          window.alert('お支払い停止処理が完了しました。\n今月末日までご利用いただけます。');
          await this.successPayment();
          await this.returnPayment();
        }
      } catch (err) {
        console.log(err);
      }
      return true;
    }
  }

  setReady() {
    if (this.state.my_customer !== null && this.state.coupon_list !== null && this.state.setting !== null && this.meta && this.state.cssLoaded) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  customerToState(customer, setting) {
    // 退会済みデータは画面に反映しない
    if (customer.status === constClass.STATUS.CCL) {
      return {
        ...customer,
        customer_name: '',
        customer_kana: '',
        gender: '',
        birth: '',
        phone_number: '',
        postal_code: '',
        address: '',
        card_id: '',
        mail_address: '',
        cancel_cause: '',
        privacy_policy: constClass.FLAG.OFF,
        agreement: constClass.FLAG.OFF,
        t_customer_detail: [],
      }
    } else {
      return {
        ...customer,
        customer_name: customer.customer_name ? customer.customer_name : '',
        customer_kana: customer.customer_kana ? customer.customer_kana : '',
        gender: customer.gender ? customer.gender : '',
        birth: customer.birth ? customer.birth : '',
        phone_number: customer.phone_number ? customer.phone_number : '',
        postal_code: customer.postal_code ? customer.postal_code : '',
        address: customer.address ? customer.address : '',
        card_id: customer.card_id ? customer.card_id.slice(setting['PREFIX_CARD_ID'] ? setting['PREFIX_CARD_ID'].length : 0) : '',
        mail_address: customer.mail_address ? customer.mail_address : '',
        cancel_cause: '',
        privacy_policy: customer.privacy_policy ? customer.privacy_policy : constClass.FLAG.OFF,
        agreement: customer.agreement ? customer.agreement : constClass.FLAG.OFF,
      }
    }
  }

  checkInvalid() {
    const c = this.state.my_customer;
    const inputCheck = (this.state.setting['INPUT_CUSTOMER_NAME'] === constClass.INPUT.REQUIRED && !c.customer_name) ||
      (this.state.setting['INPUT_CUSTOMER_KANA'] === constClass.INPUT.REQUIRED && !c.customer_kana) ||
      (this.state.setting['INPUT_GENDER'] === constClass.INPUT.REQUIRED && !c.gender) ||
      (this.state.setting['INPUT_BIRTH'] === constClass.INPUT.REQUIRED && !c.birth) ||
      (this.state.setting['INPUT_PHONE_NUMBER'] === constClass.INPUT.REQUIRED && !c.phone_number) ||
      (this.state.setting['INPUT_POSTAL_CODE'] === constClass.INPUT.REQUIRED && !c.postal_code) ||
      (this.state.setting['INPUT_ADDRESS'] === constClass.INPUT.REQUIRED && !c.address) ||
      (this.state.setting['INPUT_MAIL_ADDRESS'] === constClass.INPUT.REQUIRED && !c.mail_address) ||
      (this.state.setting['INPUT_CARD_ID'] === constClass.INPUT.REQUIRED && !c.card_id) ||
      ((!!this.state.setting['TERMS_OF_SERVICE_URL'] || !!this.state.setting['PRIVACYPOLICY_URL']) && c.privacy_policy !== constClass.FLAG.ON) ||
      ((!!this.state.setting['ADDITIONAL_AGREEMENT']) && c.agreement !== constClass.FLAG.ON);
    const inputDetailCheck = this.state.customer_detail_type.filter(c => c.required).reduce((prev, type) => prev || !(Common.getCustomerDetailObject(c.t_customer_detail)[type.entry_type]), false);
    const m = this.state.message;
    const errorCheck = !!m.mail_address || !!m.postal_code || !!m.card_id || !!m.birth || !!m.phone_number;
    return inputCheck || inputDetailCheck || errorCheck;
  }

  getCouponCode(id) {
    return this.state.my_customer.m_coupon_code.find(c => c.coupon_id === id) ? this.state.my_customer.m_coupon_code.find(c => c.coupon_id === id) : {};
  }

  getCurrentTicket() {
    return this.state.ticket_list.find(t => t.ticket_id === this.props.current_id) ? this.state.ticket_list.find(t => t.ticket_id === this.props.current_id) : {};
  }

  async getTicketListIssue() {
    await this.refreshData();
  }

  getCurrentTicketIssue() {
    return this.state.ticket_issuelist.find(t => t.ticket_id === this.props.current_id && t.issue_count === this.props.issue_count) ? this.state.ticket_issuelist.find(t => t.ticket_id === this.props.current_id && t.issue_count === this.props.issue_count) : {};
  }

  getTicketUse(id) {
    return this.state.my_customer.t_ticket_use.find(t => t.ticket_id === id) ? this.state.my_customer.t_ticket_use.find(t => t.ticket_id === id) : {};
  }

  getTicket(id) {
    return this.state.ticket_list.find(t => t.ticket_id === id) ? this.state.ticket_list.find(t => t.ticket_id === id) : {};
  }

  getTicketIssue(count, id) {
    return this.state.ticket_issuelist.find(t => t.ticket_id === id && t.issue_count === count) ? this.state.ticket_issuelist.find(t => t.ticket_id === id && t.issue_count === count) : {};
  }

  resetCustomer() {
    this.setState({
      my_customer: this.customerToState(this.state.original_customer, this.state.setting),
      user_change: false,
      message: {
        mail_address: '',
        gender: '',
        birth: '',
        postal_code: '',
        card_id: '',
      },
    })
  }

  async copyTextToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      // this.setState({ copy_message: 'コピーしました。' });
      // alert('コピーしました。');
      console.log('Async: Copying to clipboard was successful!');
      return true;
    } catch (err) {
      try {
        console.log('Async: Could not copy text: ', err);
        var input = document.createElement('input');
        document.body.appendChild(input);
        input.value = text;
        input.select();
        const result = document.execCommand('copy');
        document.body.removeChild(input);
        if (!result) {
          throw new Error('document.execCommand failed');
        }
        console.log('document.execCommand: Copying to clipboard was successful!');
        return true;
      } catch (err) {
        alert('コピーに失敗しました。お手数ですがクーポンコードを直接入力してください。');
        console.error('document.execCommand: Could not copy text: ', err);
        return false;
      }
    }
  }

  async submit() {
    if (this.checkInvalid() || this.state.disabled) {
      return;
    }
    if (this.props.page === constClass.USERCHANGE) {
      if (this.state.setting['CUSTOMER_CHANGE_CONFIRM'] && !window.confirm(this.state.setting['CUSTOMER_CHANGE_CONFIRM'])) {
        return;
      }
    } else {
      if (this.state.setting['CUSTOMER_REG_CONFIRM'] && !window.confirm(this.state.setting['CUSTOMER_REG_CONFIRM'])) {
        return;
      }
    }
    this.setState({ disabled: true });
    var message = this.state.message;
    // customerデータ準備
    var data = {
      'site_id': this.state.siteId,
      'line_id': this.props.liff_access_token,
      'customer_name': this.state.my_customer.customer_name,
      'customer_kana': this.state.my_customer.customer_kana,
      'gender': this.state.my_customer.gender,
      'birth': this.state.my_customer.birth,
      'phone_number': this.state.my_customer.phone_number,
      'postal_code': this.state.my_customer.postal_code,
      'address': this.state.my_customer.address,
      'card_id': this.state.my_customer.card_id ? this.state.setting['PREFIX_CARD_ID'] + this.state.my_customer.card_id : '',
      'mail_address': this.state.my_customer.mail_address,
      'privacy_policy': this.state.my_customer.privacy_policy,
      'agreement': this.state.my_customer.agreement,
      'status': constClass.STATUS.REG,
      'register_date': this.props.page === constClass.USERCHANGE ? this.state.my_customer.register_date : new Date(),
      't_customer_detail': this.state.my_customer.t_customer_detail,
    };
    // 退会→登録のとき決済失敗のログを削除する(決済ステータスを決済停止中にする)
    if (this.state.my_customer.status === constClass.STATUS.CCL && this.state.my_customer.payment_status === constClass.PAYMENT_STATUS.ERR) {
      data.payment_status = constClass.PAYMENT_STATUS.CCL;
    }
    // API実行
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/${this.state.my_customer.customer_id}`, data);
      if (this.props.page === constClass.USERCHANGE) {
        alert('変更が完了しました。');
        await this.refreshData(true);
        this.setState({ pre_modal_flag: false });
        this.props.history.push(`/${this.state.siteId}/?page=${constClass.USER}`);
      } else {
        // alert('登録が完了しました。');
        await this.refreshData();
        // this.props.history.replace(`/${this.state.siteId}/?page=${constClass.COUPONLIST}`);
      }
      this.setState({
        disabled: false,
        message: {
          mail_address: '',
          gender: '',
          birth: '',
          postal_code: '',
          card_id: '',
          phone_number: '',
        },
        user_change: false,
      });
    } catch (err) {
      console.log(util.inspect(err));
      if (err.response && err.response.data) {
        alert(err.response.data);
      } else {
        alert('エラーが発生しました。');
      }
      this.setState({ disabled: false, message });
    }
  }

  async submitCampaign(campaign, page) {
    await this.props.setCampaignId(campaign.campaign_id);
    this.setState({ campaign });
    if (!campaign.entry_count && campaign.entry_count !== 0) {
      page = constClass.CONFIRM;
    }
    this.props.history.push(`/${this.state.siteId}/?page=${page}&campaign_id=${campaign.campaign_id}`);
    this.refreshData();
  }

  async submitCoupon(coupon_id) {
    if (this.state.disabled) {
      return;
    }
    // クリップボードコピー
    const result = (await this.copyTextToClipboard(this.getCouponCode(coupon_id).coupon_code));
    if (!result) {
      return;
    }
    // コピーメッセージの表示
    const msg = { ...this.state.popover_message, [coupon_id]: 'コピーしました' };
    this.setState({ disabled: false, popover_message: msg });
    // メッセージ削除タイマー設定
    if (!this.intervalPop[coupon_id]) {
      this.intervalPop[coupon_id] = setInterval(() => {
        const msg = { ...this.state.popover_message, [coupon_id]: undefined };
        this.setState({ disabled: false, popover_message: msg });
        clearInterval(this.intervalPop[coupon_id]);
        this.intervalPop[coupon_id] = undefined;
      }, 1000);
    }
    // this.setState({ disabled: true });
    // 未使用のクーポンを使用した際に実行
    if (!this.getCouponCode(coupon_id).use_date) {
      // 検索条件
      const params = {
        customer_id: this.state.my_customer.customer_id,
        coupon_month: Common.getMonthString(),
        coupon_code_id: this.getCouponCode(coupon_id).coupon_code_id,
        line_id: this.props.liff_access_token
      };
      // API実行
      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/coupon_code/line/customer/${this.state.siteId}/${coupon_id}/use`, params);
      } catch (err) {
        // alert('エラーが発生しました。');
        console.log(err);
      } finally {
        await this.refreshData();
      }
    }
  }

  /* 退会処理 */
  async submitCancel() {
    if (this.state.disabled) {
      return;
    }
    if (this.state.my_customer.cancel_cause === '') {
      window.alert('退会理由を選択してください。');
      return;
    }
    if (!window.confirm('退会してもよろしいですか？')) {
      return;
    }
    const params = {
      site_id: this.state.siteId,
      status: constClass.STATUS.CCL,
      line_id: this.props.liff_access_token,
      cancel_cause: this.state.my_customer.cancel_cause,
      // payment_status: constClass.PAYMENT_STATUS.CCL // 決済停止中
    };
    let res;
    try {
      this.setState({ disabled: true });
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer/line/update_status/${this.state.my_customer.customer_id}`, params);
      if (res && res.data.error) {
        alert(res.data.message);
      }
      else {
        alert('退会処理が完了しました。');
        this.setState({ modal_flag: false });
        await this.refreshData();
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      }
    } catch (err) {
      if (err.response.data !== null) {
        alert(err.response.data.message);
      } else {
        alert(err.response);
      }
    } finally {
      this.setState({ disabled: false });
    }
  }

  /* チケット使用 */
  async submitTicket(ticket_id) {
    if (this.state.disabled) {
      return;
    }
    // チケットの利用回数未満の場合に実行
    if (Math.max(...this.getTicket(ticket_id).t_ticket_use.map(t => t.ticket_times)) < this.getTicket(ticket_id).ticket_times) {
      if (!window.confirm('クーポンの使用にはスタッフの確認が必要です。使用してもよろしいですか？')) {
        return;
      }
      // 検索条件
      const params = {
        customer_id: this.state.my_customer.customer_id,
        ticket_month: Common.getMonthString(),
        line_id: this.props.liff_access_token
      };
      // API実行
      try {
        const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/line/customer/${this.state.siteId}/${ticket_id}/use`, params)).data;
        if (!result.msg) {
          window.alert('クーポンを使用しました。');
        } else if (result.msg) {
          window.alert(result.msg);
        }
      } catch (err) {
        // alert('エラーが発生しました。');
        console.log(err);
      } finally {
        await this.refreshData();
      }
    }
  }

  /* チケット使用 */
  async submitTicketIssue(issue_count, ticket_id) {
    if (this.state.disabled) {
      return;
    }
    if (!this.getTicketIssue(issue_count, ticket_id).use_date) {
      if (!window.confirm('クーポンの使用にはスタッフの確認が必要です。使用してもよろしいですか？')) {
        return;
      }
      // 検索条件
      const params = {
        customer_id: this.state.my_customer.customer_id,
        issue_count: issue_count,
        line_id: this.props.liff_access_token
      };
      // API実行
      try {
        const result = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/line/customer/${this.state.siteId}/${ticket_id}/use`, params)).data;
        if (!result.msg) {
          window.alert('クーポンを使用しました。');
        } else if (result.msg) {
          window.alert(result.msg);
        }
      } catch (err) {
        // alert('エラーが発生しました。');
        console.log(err);
      } finally {
        await this.refreshData();
      }
    }
  }

  renderHeader() {
    const titlename = () => {
      switch (this.props.page) {
        case constClass.RECEIPTSCAN: return `レシート読込`;
        case constClass.USER: return `お客様情報`;
        case constClass.USERCHANGE: return `お客様情報変更`;
        case constClass.TICKETLIST: return `利用可能なクーポン一覧`;
        case constClass.TICKETUSE: return `利用可能なクーポン一覧`;
        case constClass.TICKETISSUEUSE: return `利用可能なクーポン一覧`;
        case constClass.PAYMENT: return `お支払い情報`;
        case constClass.ENTRY: return `ポイント一覧`;
        case constClass.POINTLIST: return `ポイント一覧`;
        case constClass.CAMPAIGNLIST: return `キャンペーン`;
        case constClass.NOTCAMPAIGN: return `キャンペーン`;
        case constClass.CONFIRM: return `キャンペーン応募要項`;
        case constClass.CAMPAIGNENTRY: return `キャンペーン応募`;
        case constClass.CONFIRM2: return `キャンペーン要項`;
        case constClass.MEMBERSHIPPASS: return `会員証`;
        default: return `お客様情報入力`;
      }
    }
    return (
      <header className="header">
        <div className="row mx-0" ref={this.headerRef}>
          <div className={`col section${[constClass.TICKETLIST, constClass.TICKETUSE].includes(this.props.page) ? '' : ''} text-center`}>
            {/* <img src="logo.png" alt="logo" /> */}
            <span>{titlename(this)}</span>
          </div>
        </div>
      </header>
    );
  }
  renderPagetitle() {
    return (
      <div>
        {/* <div className="row mx-0">
          <div className="col section py-0 line-height-2-2">
            <span className="d-inline-block align-middle">{titlename(this)}</span>
          </div>
        </div> */}
      </div>
    );
  }

  renderUser(change) {
    const renderInput = (setting, name, type, suffix) => {
      const large_name = String(name).toUpperCase();
      const entry_type = {
        entry_type: name,
        required: setting['INPUT_' + large_name] === constClass.INPUT.REQUIRED ? 1 : 0,
        entry_type_name: setting['LABEL_' + large_name],
        detail_data: [],
        notice: setting['NOTICE_' + large_name],
        info: setting['INFO_' + large_name],
      }
      if (name === 'gender') {
        entry_type.detail_data = [
          { input_type: 'radio', entry_type: name, entry_seq: 1, entry_name: constClass.GENDER_NAME.MAN, entry_data: constClass.GENDER.MAN, param_name: name },
          { input_type: 'radio', entry_type: name, entry_seq: 2, entry_name: constClass.GENDER_NAME.WOMAN, entry_data: constClass.GENDER.WOMAN, param_name: name },
          { input_type: 'radio', entry_type: name, entry_seq: 3, entry_name: constClass.GENDER_NAME.OTHER, entry_data: constClass.GENDER.OTHER, param_name: name },
        ]
      } else if (name === 'birth') {
        entry_type.detail_data = [
          { input_type: type, entry_type: name, entry_seq: 1, param_name: name, range_to: '1900', use_year: setting['INPUT_BIRTH_YEAR'], use_month: setting['INPUT_BIRTH_MONTH'], use_date: setting['INPUT_BIRTH_DATE'] },
        ]
      } else {
        entry_type.detail_data = [
          { input_type: type, entry_type: name, entry_seq: 1, param_name: name, placeholder: setting['PLACEHOLDER_' + large_name], prefix: setting['PREFIX_' + large_name], max_length: name === 'postal_code' ? 7 : name === 'phone_number' ? 14 : name === 'card_id' ? 15 : null },
        ]
      }
      return [constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_' + large_name]) ? renderInputDetail(entry_type, this.state.my_customer, this.changeCustomer, suffix) : null;
    }

    const renderInputDetail = (entry_type, values, changeHandler, suffix) => {
      const setYears = (from, to) => {
        const items = [];
        const nowYear = new Date().getFullYear();
        var range_from = from ? from : nowYear;
        var range_to = to ? to : nowYear;
        items.push(<option value="" key={"-"}>- 年 -</option>);
        if (range_from < range_to) {
          for (let i = range_from; i <= range_to; i++) {
            items.push(<option value={i} key={i}>{i}年</option>);
          }
        } else {
          for (let i = range_from; i >= range_to; i--) {
            items.push(<option value={i} key={i}>{i}年</option>);
          }
        }
        return items;
      }
      const setMonth = () => {
        const items = [];
        items.push(<option value="" key={"-"}>- 月 -</option>)
        for (let i = 1; i <= 12; i++) {
          items.push(<option value={("00" + i).slice(-2)} key={i}>{i}月</option>)
        }
        return items;
      }
      const setDay = (value) => {
        const items = [];
        const year = value.slice(0, 4);
        const month = value.slice(4, 6);
        items.push(<option value="" key={"-"}>- 日 -</option>)
        for (let i = 1; i <= 31; i++) {
          if (!year || !Number(month) || (new Date(year + '/' + month + '/' + ("00" + i).slice(-2)).getDate() === i)) {
            items.push(<option value={("00" + i).slice(-2)} key={i}>{i}日</option>)
          }
        }
        return items;
      }
      const handleDateChange = (e, changeEvent) => {
        const target = e.target;
        const type = target.id.split('-')[0];
        const seq = target.id.split('-')[1];
        var temp = Number(document.getElementById(type + '-' + seq + '-y') ? document.getElementById(type + '-' + seq + '-y').value : 0) * 10000;
        temp += Number(document.getElementById(type + '-' + seq + '-m') ? document.getElementById(type + '-' + seq + '-m').value : 0) * 100;
        temp += Number(document.getElementById(type + '-' + seq + '-d') ? document.getElementById(type + '-' + seq + '-d').value : 0);
        changeEvent({
          target: {
            type: 'text',
            name: target.name,
            value: ("00000000" + temp).slice(-8)
          }
        })
      }
      return <React.Fragment key={entry_type.entry_type}>
        <div className="input-label row mx-0 px-3-env">
          <div className="col px-0">
            {change && <span className={`text-${entry_type.required === 1 ? 'required' : 'optional'}`}>{entry_type.required === 1 ? '必須' : '任意'}</span>}<span>{entry_type.entry_type_name}</span>
          </div>
        </div>
        {change &&
          <div className="input-body form-group">
            <div className="input-control row mx-0 px-3-env text-left">
              {entry_type.detail_data.map(d =>
                <React.Fragment key={d.entry_type + '-' + d.entry_seq}>
                  {['text', 'number'].includes(d.input_type) && <React.Fragment>
                    {d.prefix && <div className={`col-auto px-0 align-self-center ${d.entry_type + '-' + d.entry_seq + '-prefix'}`}><span>{d.prefix}</span></div>}
                    <div className={`col px-0 align-self-center ${d.entry_type + '-' + d.entry_seq}`}><input type={d.input_type} className="form-control" id={d.entry_type + '-' + d.entry_seq} name={d.entry_type} value={values[d.entry_type]} onChange={changeHandler} placeholder={d.placeholder} maxLength={d.max_length}></input></div>
                    {suffix && <div className={`col-auto px-0 align-self-center ${d.entry_type + '-' + d.entry_seq + '-suffix'}`}><span>{suffix}</span></div>}
                  </React.Fragment>}
                  {d.input_type === 'radio' && <React.Fragment>
                    <div className={`col px-0 align-self-center ${d.entry_type + '-' + d.entry_seq}`} key={d.entry_type + '-' + d.entry_seq}>
                      <div className="row px-0 mx-0">
                        <div className="col-3 px-0 mx-0 align-self-center">
                          <input type="radio" className="form-control" id={d.entry_type + '-' + d.entry_seq} name={d.entry_type} onChange={changeHandler} value={d.entry_data} checked={values[d.entry_type] === d.entry_data} />
                        </div>
                        <div className="col-9 px-1 mx-0 align-self-center">
                          <label className="my-0" htmlFor={d.entry_type + '-' + d.entry_seq}>{d.entry_name}</label>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>}
                  {d.input_type === 'date' && <React.Fragment>
                    <div className={`col px-0 align-self-center ${d.entry_type + '-' + d.entry_seq}`} key={d.entry_type + '-' + d.entry_seq}>
                      <div className="row px-0 mx-0">
                        {d.use_year === constClass.FLAG.ON && <div className={`col-4 px-0 mx-0 align-self-center ${d.entry_type + '-' + d.entry_seq + '-y'}`}>
                          <select className="form-control text-center" id={d.entry_type + '-' + d.entry_seq + '-y'} name={d.entry_type} onChange={(e) => handleDateChange(e, changeHandler)} value={values[d.entry_type].slice(0, 4)}>
                            {setYears(d.range_from, d.range_to)}
                          </select>
                        </div>}
                        {d.use_month === constClass.FLAG.ON && <div className={`col-4 px-1 mx-0 align-self-center ${d.entry_type + '-' + d.entry_seq + '-m'}`}>
                          <select className="form-control text-center" id={d.entry_type + '-' + d.entry_seq + '-m'} name={d.entry_type} onChange={(e) => handleDateChange(e, changeHandler)} value={values[d.entry_type].slice(4, 6)}>
                            {setMonth()}
                          </select>
                        </div>}
                        {d.use_date === constClass.FLAG.ON && <div className={`col-4 px-1 mx-0 align-self-center ${d.entry_type + '-' + d.entry_seq + '-d'}`}>
                          <select className="form-control text-center" id={d.entry_type + '-' + d.entry_seq + '-d'} name={d.entry_type} onChange={(e) => handleDateChange(e, changeHandler)} value={values[d.entry_type].slice(6, 8)}>
                            {setDay(values[d.entry_type])}
                          </select>
                        </div>}
                      </div>
                    </div>
                  </React.Fragment>}
                  {d.input_type === 'checkbox' && <React.Fragment>
                    <div className={`col px-0 align-self-center ${d.entry_type + '-' + d.entry_seq}`} key={d.entry_type + '-' + d.entry_seq}>
                      <div className="row px-0 mx-0">
                        <div className="col-2">
                          <input type="checkbox" className="form-control" id={d.entry_type + '-' + d.entry_seq} name={d.entry_type} value="true" checked={values[d.entry_type] === constClass.FLAG.ON} onChange={changeHandler}></input>
                        </div>
                        <div className="col-10">
                          <label className="my-0" htmlFor={d.entry_type + '-' + d.entry_seq}>{d.entry_name}</label>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>}
                </React.Fragment>
              )}
            </div>
            {entry_type.notice &&
              <div className="input-notice row mx-0 px-3-env text-left">
                <div className="col px-0 font-weight-normal">{Common.getBrString(entry_type.notice)}</div>
              </div>
            }
            {this.state.message[entry_type.entry_type] &&
              <div className="input-message row mx-0 px-3-env text-left">
                <div className="col px-0 text-danger font-weight-normal">{Common.getBrString(this.state.message[entry_type.entry_type])}</div>
              </div>
            }
            {entry_type.info &&
              <div className="input-info row mx-0 px-3-env text-left text-info mt-3">
                <div className="col px-0 font-weight-normal">{Common.getBrString(entry_type.info)}</div>
              </div>
            }
          </div>
        }
        {!change &&
          <div className="input-disp row mx-0 px-3-env text-left form-group">
            {['text', 'number'].includes(entry_type.detail_data.find(() => true).input_type) && <div className="col px-3 py-1 align-self-center border">
              {values[entry_type.entry_type] ? (entry_type.detail_data.find(() => true).prefix ? entry_type.detail_data.find(() => true).prefix + values[entry_type.entry_type] : values[entry_type.entry_type]) : '未登録'}
            </div>}
            {['radio'].includes(entry_type.detail_data.find(() => true).input_type) && <div className="col px-3 py-1 align-self-center border">
              {values[entry_type.entry_type] ? entry_type.detail_data.find(p => p.entry_data === values[entry_type.entry_type]).entry_name : '未登録'}
            </div>}
            {['date'].includes(entry_type.detail_data.find(() => true).input_type) && <div className="col px-3 py-1 align-self-center border">
              {values[entry_type.entry_type] ? Common.getDateObject(values[entry_type.entry_type]).str : '未登録'}
            </div>}
          </div>
        }
      </React.Fragment>
    }
    return (
      <div id="user" className="row mx-3 my-3 px-0-env">
        <div className="col bg-white px-0">
          <div>
            {((this.props.page === null || this.props.page === undefined || this.props.page === '') && this.state.setting['CUSTOMER_REG_HEADER']) &&
              <div className="customer-header row mx-0 pt-2 pb-1 text-center">
                <div className="col py-0 px-0 align-self-center">
                  {Common.getBrString(this.state.setting['CUSTOMER_REG_HEADER'])}
                </div>
              </div>
            }
            {(this.props.page === constClass.USER && this.state.setting['CUSTOMER_DISP_HEADER']) &&
              <div className="customer-header row mx-0 pt-2 pb-1 text-center">
                <div className="col py-0 px-0 align-self-center">
                  {Common.getBrString(this.state.setting['CUSTOMER_DISP_HEADER'])}
                </div>
              </div>
            }
            {(this.props.page === constClass.USERCHANGE && this.state.setting['CUSTOMER_CHANGE_HEADER']) &&
              <div className="customer-header row mx-0 pt-2 pb-1 text-center">
                <div className="col py-0 px-0 align-self-center">
                  {Common.getBrString(this.state.setting['CUSTOMER_CHANGE_HEADER'])}
                </div>
              </div>
            }
            <div className="customer-input row mx-0 pt-2 pb-1">
              <div className="col py-0 px-0 align-self-center">
                {renderInput(this.state.setting, 'customer_name', 'text')}
                {renderInput(this.state.setting, 'customer_kana', 'text')}
                {renderInput(this.state.setting, 'gender', 'radio')}
                {renderInput(this.state.setting, 'birth', 'date')}
                {renderInput(this.state.setting, 'phone_number', 'text')}
                {renderInput(this.state.setting, 'postal_code', 'text', <div className='px-1'><button className="btn btn-enable" onClick={() => this.searchPostalCode()}>住所から検索</button></div>)}
                {renderInput(this.state.setting, 'address', 'text')}
                {renderInput(this.state.setting, 'mail_address', 'text')}
                {renderInput(this.state.setting, 'card_id', 'text')}
                {this.state.customer_detail_type.map(t => renderInputDetail(t, Common.getCustomerDetailObject(this.state.my_customer.t_customer_detail), this.changeCustomerDetail))}

              </div>
            </div>
            {change &&
              <React.Fragment>
                {(this.state.setting['ADDITIONAL_AGREEMENT']) &&
                  <React.Fragment>
                    <div className="customer-agreement additional-agreement row mx-0 px-3-env py-0 mt-1 mb-1">
                      <div className="col-2">
                        <input type="checkbox" className="form-control" id="agreement" name="agreement" value="true" checked={this.state.my_customer.agreement === constClass.FLAG.ON} onChange={this.changeCustomer}></input>
                      </div>
                      <div className="col-10">
                        <label className="mb-0" htmlFor="agreement">
                          {Common.getBrString(this.state.setting['ADDITIONAL_AGREEMENT'])}
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                }
                {this.state.setting['ADDITIONAL_AGREEMENT_CONTENT'] &&
                  <div className="additional-agreement-content row mx-0 px-3-env py-0 mt-1 mb-1">
                    <div className="col" dangerouslySetInnerHTML={{ __html: this.state.setting['ADDITIONAL_AGREEMENT_CONTENT'] }}>
                    </div>
                  </div>
                }
                {(this.state.setting['TERMS_OF_SERVICE_URL'] || this.state.setting['PRIVACYPOLICY_URL']) &&
                  <div className="customer-agreement row mx-0 px-3-env py-0 mt-1 mb-1">
                    <div className="col-2">
                      <input type="checkbox" className="form-control" id="privacy_policy" name="privacy_policy" value="true" checked={this.state.my_customer.privacy_policy === constClass.FLAG.ON} onChange={this.changeCustomer}></input>
                    </div>
                    <div className="col-10">
                      <label className="mb-0" htmlFor="privacy_policy">
                        {this.state.setting['TERMS_OF_SERVICE_URL'] && <a href={this.state.setting['TERMS_OF_SERVICE_URL']} target="_blank" rel="noreferrer"><u>利用規約</u></a>}
                        {(this.state.setting['TERMS_OF_SERVICE_URL'] && this.state.setting['PRIVACYPOLICY_URL']) && '・'}
                        {this.state.setting['PRIVACYPOLICY_URL'] && <a href={this.state.setting['PRIVACYPOLICY_URL']} target="_blank" rel="noreferrer"><u>プライバシーポリシー</u></a>}
                        に同意します。
                      </label>
                    </div>
                  </div>
                }
              </React.Fragment>
            }
            {!change &&
              <div>
                {this.state.setting['TERMS_OF_SERVICE_URL'] &&
                  <div className="customer-link row mx-0 px-3-env pt-2 pb-1">
                    <div className="col text-center">
                      <a href={this.state.setting['TERMS_OF_SERVICE_URL']} target="_blank" rel="noreferrer"><u>利用規約</u></a>
                    </div>
                  </div>
                }
                {this.state.setting['PRIVACYPOLICY_URL'] &&
                  <div className="customer-link row mx-0 px-3-env pt-1 pb-1">
                    <div className="col text-center">
                      <a href={this.state.setting['PRIVACYPOLICY_URL']} target="_blank" rel="noreferrer"><u>プライバシーポリシー</u></a>
                    </div>
                  </div>
                }
              </div>
            }
            {this.state.setting['LP_URL'] &&
              <div className="customer-link row mx-0 px-3-env pt-1 pb-2">
                <div className="col text-center">
                  <a href={Common.getExternalUrl(this.state.setting['LP_URL'])} target="_blank" rel="noreferrer"><u>{this.site_name}について</u></a>
                </div>
              </div>
            }
            {change &&
              <div className="customer-submit pt-2 pb-3">
                <div className="row mx-0 px-3-env">
                  <div className="col-12 text-center p-0">
                    <button
                      disabled={this.checkInvalid() || this.state.disabled}
                      className="btn-lg btn-active w-100 py-2"
                      onClick={() => { this.submit() }}>
                      {this.props.page === constClass.USERCHANGE ? this.state.setting['CUSTOMER_CHANGE_BUTTON'] : this.state.setting['CUSTOMER_REG_BUTTON']}
                    </button>
                  </div>
                  {(this.props.page !== constClass.USERCHANGE && this.state.setting['CUSTOMER_REG_BANNER']) &&
                    <div className="col-12 text-center px-0 pt-2">
                      <img src={`/${this.state.siteId}/${this.state.setting['CUSTOMER_REG_BANNER']}`} className="w-100" alt={"バナー"} />
                    </div>
                  }
                </div>
                {(this.props.page !== constClass.USERCHANGE && this.state.setting['CUSTOMER_REG_BUTTON_NOTICE']) &&
                  <div className="customer-submit-notice row mx-0 px-3-env">
                    <div className="col-12 text-center p-0">
                      <span>{this.state.setting['CUSTOMER_REG_BUTTON_NOTICE']}</span>
                    </div>
                  </div>
                }
                {(this.props.page === constClass.USERCHANGE && this.state.setting['CUSTOMER_CHANGE_BUTTON_NOTICE']) &&
                  <div className="customer-submit-notice row mx-0 px-3-env">
                    <div className="col-12 text-center p-0">
                      <span>{this.state.setting['CUSTOMER_CHANGE_BUTTON_NOTICE']}</span>
                    </div>
                  </div>
                }
                {this.props.page === constClass.USERCHANGE &&
                  <div className="customer-back row mx-0 px-3-env pt-2 pb-3">
                    <div className="col-12 text-center p-0">
                      <button
                        disabled={this.state.disabled}
                        className="btn btn-enable w-100 py-2"
                        onClick={() => { this.resetCustomer(); this.props.history.push(`/${this.state.siteId}/?page=${constClass.USER}`); this.scrollPageTop(); }}>
                        保存せずに戻る
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
            {!change &&
              <React.Fragment>
                <div className="customer-submit row mx-0 px-3-env pt-2 pb-1">
                  <div className="col-12 text-center p-0">
                    <button
                      disabled={this.state.disabled}
                      className="btn-lg btn-active w-100 py-2"
                      onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.USERCHANGE}`); this.scrollPageTop(); }}>
                      お客様情報変更
                    </button>
                  </div>
                </div>
                {(this.state.setting[constClass.SETTING.PAYMENT_REQUIRED] === constClass.FLAG.ON && [constClass.USER].includes(this.props.page)) &&
                  <React.Fragment>
                    <div className="customer-payment row mx-0 px-3-env pt-1 pb-1">
                      <div className="col p-0 text-center">
                        <button
                          disabled={this.state.disabled}
                          className="btn-lg btn-info w-100"
                          onClick={() => { this.submitPayment(this.state.my_customer, true) }}>
                          <p className="mb-0 line-height-1-0">
                            お支払い情報{(!this.state.my_customer.payment_status || [constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.CCL].includes(this.state.my_customer.payment_status)) ? '登録' : '変更'}
                          </p>
                          <p className="mb-0 line-height-1-0">
                            <small>({(!this.state.my_customer.payment_status || [constClass.PAYMENT_STATUS.UPAY, constClass.PAYMENT_STATUS.CCL].includes(this.state.my_customer.payment_status)) ? '退会' : 'お支払い停止'}はこちら)</small>
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="row mx-0 px-3-env pt-1 pb-3">
                      <div className="col p-0 text-center">
                        {(this.state.my_customer.status === constClass.STATUS.REG && this.state.my_customer.payment_status === constClass.PAYMENT_STATUS.CCL && new Date(this.state.my_customer.last_payment_date).getMonth() === new Date().getMonth()) &&
                          <span>{new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月'}末日までご利用いただけます。</span>
                        }
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            {/* {(this.props.page === constClass.USER && !change) &&
              <div className="row mx-0 px-3-env pt-2 pb-3">
                <div className="col-12 text-center p-0">
                  <button
                    disabled={this.state.disabled}
                    className="btn btn-link"
                    onClick={() => { this.setState({ modal_flag: true }) }}>
                    <u>退会する</u>
                  </button>
                </div>
              </div>
            } */}
            <PostalCodeSearch
              siteId={this.state.siteId}
              openFlag={this.state.postal_code_flag}
              onClose={this.closePostalCode}
              onSuccess={this.setPostalCode}
            />
          </div>
        </div>
      </div>
    );
  }

  renderReceiptScan() {
    return (
      <ReceiptScan
        coupon_list={this.state.coupon_list.filter(c => !this.state.ng_coupon_list.includes(c.coupon_id))}//払い出せなかったクーポンを除く
        coupon_codes={this.state.my_customer.m_coupon_code}
        customerId={this.state.my_customer.customer_id}
        couponClick={(id) => () => this.submitCoupon(id)}
        siteId={this.state.siteId}
        liffAccessToken={this.props.liff_access_token}
        refresh={() => this.getTicketListIssue()}
        setting={this.state.setting}
      />
    );
  }

  renderCouponList() {
    return (
      <CouponList
        coupon_list={this.state.coupon_list.filter(c => !this.state.ng_coupon_list.includes(c.coupon_id))}//払い出せなかったクーポンを除く
        coupon_codes={this.state.my_customer.m_coupon_code}
        couponClick={(id) => () => this.submitCoupon(id)}
        siteId={this.state.siteId}
        popover_message={this.state.popover_message}
      />
    );
  }

  renderTicketList() {
    const couponcheck = (
      this.state.ticket_list.filter(ticket => {
        const hasAvailableTimes = (ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) < ticket.ticket_times);
        const isUnderFirstComeServed = ticket.first_come_served && ticket.first_come_served > 0;
        const isInTicketAllSumList = this.state.ticket_all_sum_list.find(t => t.ticket_id === ticket.ticket_id);
        const isUnderTotalTicketTimes = isInTicketAllSumList && Number(isInTicketAllSumList.total_ticket_times) < ticket.first_come_served;
        // isUnderFirstComeServedがtrueの場合のみ、isInTicketAllSumListとisUnderTotalTicketTimesを評価
        const firstComeServedCondition = isUnderFirstComeServed ? isInTicketAllSumList && isUnderTotalTicketTimes : true; // isUnderFirstComeServedがfalseの場合は条件を無視   
        return (
          (hasAvailableTimes && firstComeServedCondition) ||
          ticket.ticket_times === 0
        );
      }).length === 0
      &&
      this.state.ticket_issuelist.filter(ticket => {
        const hasAvailableTimes = (ticket.use_date === null || ticket.use_date === '') && ticket.invalid_flag !== constClass.FLAG.ON;
        const isUnderFirstComeServed = ticket.first_come_served && ticket.first_come_served > 0;
        const isInTicketAllSumList = this.state.ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id);
        const isUnderTotalTicketTimes = isInTicketAllSumList && Number(isInTicketAllSumList.total_ticket_issue_times) < ticket.first_come_served;
        // isUnderFirstComeServedがtrueの場合のみ、isInTicketAllSumListとisUnderTotalTicketTimesを評価
        const firstComeServedCondition = isUnderFirstComeServed ? isInTicketAllSumList && isUnderTotalTicketTimes : true; // isUnderFirstComeServedがfalseの場合は条件を無視
        return (
          (hasAvailableTimes && firstComeServedCondition)
        );
      }).length === 0
    );

    if (couponcheck) {
      return (
        <div className="mt-4 text-center">
          現在、利用可能なクーポンはありません
        </div>
      );
    }

    return (
      <div>
        <TicketList
          ticket_list={this.state.ticket_list.filter(ticket => {
            const hasAvailableTimes = (ticket.ticket_times > 0 && (Math.max(...ticket.t_ticket_use.map(t => t.ticket_times))) < ticket.ticket_times);
            const isUnderFirstComeServed = ticket.first_come_served && ticket.first_come_served > 0;
            const isInTicketAllSumList = this.state.ticket_all_sum_list.find(t => t.ticket_id === ticket.ticket_id);
            const isUnderTotalTicketTimes = isInTicketAllSumList && Number(isInTicketAllSumList.total_ticket_times) < ticket.first_come_served;
            // isUnderFirstComeServedがtrueの場合のみ、isInTicketAllSumListとisUnderTotalTicketTimesを評価
            const firstComeServedCondition = isUnderFirstComeServed ? isInTicketAllSumList && isUnderTotalTicketTimes : true; // isUnderFirstComeServedがfalseの場合は条件を無視   
            return (
              (hasAvailableTimes && firstComeServedCondition) ||
              ticket.ticket_times === 0
            );
          })}
          ticketClick={(id) => () => this.props.history.push(`/${this.state.siteId}/?page=${constClass.TICKETUSE}&current_id=${id}`)}
          siteId={this.state.siteId}
          setting={this.state.setting}
        />
        <TicketIssueList
          siteId={this.state.siteId}
          ticket_issuelist={this.state.ticket_issuelist.filter(ticket => {
            const hasAvailableTimes = (ticket.use_date === null || ticket.use_date === '') && ticket.invalid_flag !== constClass.FLAG.ON;
            const isUnderFirstComeServed = ticket.first_come_served && ticket.first_come_served > 0;
            const isInTicketAllSumList = this.state.ticket_issue_all_sum_list.find(t => t.ticket_id === ticket.ticket_id);
            const isUnderTotalTicketTimes = isInTicketAllSumList && Number(isInTicketAllSumList.total_ticket_issue_times) < ticket.first_come_served;
            // isUnderFirstComeServedがtrueの場合のみ、isInTicketAllSumListとisUnderTotalTicketTimesを評価
            const firstComeServedCondition = isUnderFirstComeServed ? isInTicketAllSumList && isUnderTotalTicketTimes : true; // isUnderFirstComeServedがfalseの場合は条件を無視
            return (
              (hasAvailableTimes && firstComeServedCondition)
            );
          })}
          ticketIssueClick={(count, id) => () => this.props.history.push(`/${this.state.siteId}/?page=${constClass.TICKETISSUEUSE}&current_id=${id}&issue_count=${count}`)}
          setting={this.state.setting}
        />
      </div>
    );
  }

  renderTicket() {
    return (
      this.props.current_id &&
      <Ticket
        ticket={this.getCurrentTicket()}
        ticketClick={(id) => () => this.submitTicket(id)}
        backClick={() => this.historyBack()}
        disabled={this.state.disabled}
        siteId={this.state.siteId}
        ticket_all_sum_list={this.state.ticket_all_sum_list}
      />
    );
  }

  renderTicketIssue() {
    return (
      <TicketIssue
        ticket={this.getCurrentTicketIssue()}
        ticketIssueClick={(count, id) => () => this.submitTicketIssue(count, id)}
        backClick={() => this.historyBackTicketlist()}
        disabled={this.state.disabled}
        siteId={this.state.siteId}
        ticketId={this.props.current_id}
        customerId={this.state.my_customer.customer_id}
        issueCount={this.props.issue_count}
        lineId={this.props.liff_access_token}
        ticket_issue_all_sum_list={this.state.ticket_issue_all_sum_list}
      />
    );
  }

  renderPayment() {
    return (
      <Payment
        {...this.state.payment_data}
        siteId={this.state.siteId}
        lineId={this.props.liff_access_token}
        returnFunc={this.returnPayment}
        backFunc={this.backPayment}
        cancelFunc={this.cancelPayment}
        successFunc={this.successPayment}
        setting={this.state.setting}
      />
    );
  }

  renderCampaign() {
    return (
      <div>
        {this.state.campaignlist.filter(c => moment(c.campaign_close) >= moment()).map((c, idx) => ( // 公開期間内のみ表示
          <div className="mx-3 my-3 bg-white border" key={idx}>
            <div className="row mx-0 px-3-env pt-2 pb-1 card-header">
              <div className="col py-1 px-1 align-self-center text-center">
                <h4 className="p-0 m-0">{c.campaign_name}</h4>
              </div>
            </div>
            <div className="row mx-0 px-3-env pt-2 pb-1" key={idx}>
              <div className="col py-1 px-1 align-self-center text-center">
                <div>開催期間：{moment(c.campaign_start).format('YYYY/MM/DD')}～{moment(c.campaign_end).format('YYYY/MM/DD')}</div>
                {moment() > moment(c.campaign_end) &&
                  <div>
                    <div className="py-2">開催期間終了</div>
                    {(this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()) && this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()).status === constClass.STATUS.REG) &&
                      <button className="btn-lg btn-enable w-100 py-2"
                        onClick={() => { this.submitCampaign(c, ""); }}>
                        結果を表示
                      </button>
                    }
                  </div>
                }
                {moment() <= moment(c.campaign_end) &&
                  <div>
                    {this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()) && this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()).status === constClass.STATUS.REG && c.entry_count !== 0 &&
                      <button className="btn-lg btn-active w-100 py-2"
                        onClick={() => { this.submitCampaign(c, constClass.POINTLIST); }}>
                        表示
                      </button>
                    }
                    {this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()) && this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()).status === constClass.STATUS.REG && c.entry_count === 0 &&
                      <button className="btn-lg btn-active w-100 py-2"
                        onClick={() => { this.submitCampaign(c, constClass.CONFIRM2); }}>
                        表示
                      </button>
                    }
                    {(!this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()) || !(this.state.my_customer.t_entry.find(e => e.campaign_id.toString() === c.campaign_id.toString()).status === constClass.STATUS.REG)) &&
                      <button className="btn-lg btn-active w-100 py-2"
                        onClick={() => { this.submitCampaign(c, constClass.CONFIRM); }}>
                        参加
                      </button>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderMembershippass() {
    return (
      <MembershipPass
        siteId={this.state.siteId}
        customerId={this.state.my_customer.customer_id}
        lineId={this.state.my_customer.line_id}
        liffAccessToken={this.props.liff_access_token}
        setting={this.state.setting}
      />
    );
  }

  renderCampaignEntry() {
    return (
      <CampaignEntry
        siteId={this.state.siteId}
        customer_id={this.state.my_customer.customer_id}
        campaign={this.state.campaign}
        my_entry={this.state.my_entry}
        liffAccessToken={this.props.liff_access_token}
        setting={this.state.setting}
      />
    );
  }

  renderBottom() {
    const tabArray = [];
    if (Common.getFunctionEnabled(this.state.setting, 'CAMPAIGN') && Common.getFunctionEnabled(this.state.setting, 'CAMPAIGN_LIST')) {
      const pageList = [constClass.CAMPAIGNLIST, constClass.CONFIRM, constClass.CONFIRM2, constClass.POINTLIST, constClass.CAMPAIGNENTRY];
      tabArray.push(<div className="col text-center p-0">
        <button
          disabled={this.state.disabled}
          className={`btn btn-submit${pageList.includes(this.props.page) ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
          onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.CAMPAIGNLIST}`); /*this.resetCustomer();*/ this.scrollPageTop(); }}>
          <div><img className="icon d-block mx-auto" src={pageList.includes(this.props.page) ? WhiteCampaignImg : CampaignIconImg} alt="キャンペーン" /></div>
          <div>キャンペーン</div>
        </button>
      </div>);
    }
    if (Common.getFunctionEnabled(this.state.setting, 'COUPON')) {
      tabArray.push(<div className="col text-center p-0">
        <button
          disabled={this.state.disabled}
          className={`btn btn-submit${this.props.page === constClass.COUPONLIST ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
          onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.COUPONLIST}`); this.resetCustomer(); this.scrollPageTop(); }}>
          <div><img className="icon d-block mx-auto" src={this.props.page === constClass.COUPONLIST ? WhiteTicketImg : DigitalcardTicketImg} alt="クーポン" /></div>
          <div>クーポン</div>
        </button>
      </div>);
    }
    if (Common.getFunctionEnabled(this.state.setting, 'RECEIPTSCAN')) {
      tabArray.push(<div className="col text-center p-0">
        <button
          disabled={this.state.disabled}
          className={`btn btn-submit${this.props.page === constClass.RECEIPTSCAN ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
          onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.RECEIPTSCAN}`); this.resetCustomer(); this.scrollPageTop(); }}>
          <div><img className="icon d-block mx-auto" src={this.props.page === constClass.RECEIPTSCAN ? WhiteReceiptImg : ReceiptImg} alt="レシート" /></div>
          <div>レシート</div>
        </button>
      </div>);
    }
    if (Common.getFunctionEnabled(this.state.setting, 'TICKET')) {
      tabArray.push(<div className="col text-center p-0">
        <button
          disabled={this.state.disabled}
          className={`btn btn-submit${this.props.page === constClass.TICKETLIST || this.props.page === constClass.TICKETUSE || this.props.page === constClass.TICKETISSUEUSE ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
          onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.TICKETLIST}`); this.refreshData(); this.resetCustomer(); this.scrollPageTop(); }}>
          <div><img className="icon d-block mx-auto" src={this.props.page === constClass.TICKETLIST || this.props.page === constClass.TICKETUSE || this.props.page === constClass.TICKETISSUEUSE ? WhiteTicketImg : DigitalcardTicketImg} alt="チケット" /></div>
          <div>クーポン</div>
        </button>
      </div>);
    }
    tabArray.push(<div className="col text-center p-0">
      <button
        disabled={this.state.disabled}
        className={`btn btn-submit${this.props.page === constClass.USER || this.props.page === constClass.USERCHANGE ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
        onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.USER}`); this.scrollPageTop(); }}>
        <div><img className="icon d-block mx-auto" src={this.props.page === constClass.USER || this.props.page === constClass.USERCHANGE ? WhitePersonImg : DigitalcardPersonImg} alt="ご登録情報" /></div>
        <div>登録情報</div>
      </button>
    </div>);
    if (Common.getFunctionEnabled(this.state.setting, 'MEMBERCARD')) {
      tabArray.push(<div className="col text-center p-0">
        <button
          disabled={this.state.disabled}
          className={`btn btn-submit${this.props.page === constClass.MEMBERSHIPPASS ? '' : '-enable'} w-100 py-2 p-2-env-bottom`}
          onClick={() => { this.props.history.push(`/${this.state.siteId}/?page=${constClass.MEMBERSHIPPASS}`); this.scrollPageTop(); }}>
          <div><img className="icon d-block mx-auto" src={this.props.page === constClass.MEMBERSHIPPASS ? WhiteMembershipPassImg : MembershipPassImg} alt="会員証" /></div>
          <div>会員証</div>
        </button>
      </div>);
    }

    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100" ref={this.footerRef}>
          <div className="row mx-0">
            {([constClass.COUPONLIST, constClass.USER, constClass.USERCHANGE, constClass.TICKETLIST, constClass.TICKETUSE, constClass.CAMPAIGNLIST, constClass.MEMBERSHIPPASS, constClass.CONFIRM, constClass.CONFIRM2, constClass.POINTLIST, constClass.CAMPAIGNENTRY, constClass.RECEIPTSCAN, constClass.TICKETISSUEUSE].includes(this.props.page)) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  {tabArray.map((tab, idx) =>
                    <React.Fragment key={idx}>
                      {(idx > 0 && idx % 4 === 0) &&
                        <div className="w-100"></div>
                      }
                      {tab}
                    </React.Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }

  render() {
    return (
      <React.Fragment>
        <DocumentMeta {...this.meta} />
        {this.state.ready ? <div className="liff-top bg-lightwhite" style={{ '--header-height': `${this.state.headerHeight}px`, '--footer-height': `${this.state.footerHeight}px` }}>

          <ScrollToTop />
          {this.state.setting['HIDE_HEADER'] !== constClass.FLAG.ON && this.renderHeader()}
          <div className={`page-${(this.props.page === undefined || this.props.page === null || this.props.page === '' || this.props.page === constClass.PAYMENT) ? 'full' : 'btn'}`} ref={this.pageRef}>
            {/* {this.renderPagetitle()} */}
            {(this.props.page === constClass.CONFIRM || this.props.page === constClass.CONFIRM2) &&
              <div>
                {this.state.campaign &&
                  <Liffconfirm
                    {...this.props} {...{ condition: { campaign: this.state.campaign, my_customer: this.state.my_customer, my_entry: this.state.my_entry, setting: this.state.setting } }}
                  />
                }
                {!this.state.campaign && <Loading />}
              </div>
            }
            {(this.props.page === constClass.POINTLIST || this.props.page === constClass.ENTRY) && this.state.my_entry.status === constClass.STATUS.REG &&
              <Pointcard
                {...this.props} {...{ condition: { campaign: this.state.campaign, my_customer: this.state.my_customer } }}
              />
            }
            {(this.props.page === undefined || this.props.page === null || this.props.page === '') &&
              this.renderUser(true)
            }
            {this.props.page === constClass.MEMBERSHIPPASS &&
              this.renderMembershippass()
            }
            {this.props.page === constClass.RECEIPTSCAN &&
              this.renderReceiptScan()
            }
            {this.props.page === constClass.COUPONLIST &&
              this.renderCouponList()
            }
            {this.props.page === constClass.USER &&
              this.renderUser(false)
            }
            {this.props.page === constClass.USERCHANGE &&
              this.renderUser(true)
            }
            {this.props.page === constClass.TICKETLIST &&
              this.renderTicketList()
            }
            {this.props.page === constClass.TICKETUSE &&
              this.renderTicket()
            }
            {this.props.page === constClass.TICKETISSUEUSE &&
              this.renderTicketIssue()
            }
            {this.props.page === constClass.PAYMENT &&
              this.renderPayment()
            }
            {this.props.page === constClass.CAMPAIGNLIST &&
              <div>
                {this.renderCampaign()}
              </div>
            }
            {this.props.page === constClass.CAMPAIGNENTRY &&
              this.renderCampaignEntry()
            }
          </div>
          {this.state.setting['HIDE_FOOTER'] !== constClass.FLAG.ON && this.renderBottom()}
          {this.state.disabled && <Loading />}
          <Modal isOpen={this.state.modal_flag} style={this.modalStyle} onRequestClose={() => this.setState({ modal_flag: false })}>
            <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0 align-self-center">
                  <span>退会する理由を教えてください。<br />
                    一つを選んでください。</span>
                </div>
                <div className="col-auto px-0 align-self-start">
                  <div className="text-right px-0" onClick={() => this.setState({ modal_flag: false })}><img className="icon" src={CloseImg} alt="閉じる" /></div>
                </div>
              </div>
              <div className="row mx-0 px-3-env text-left">
                <div className="col-1 px-0 align-self-center" />
                <div className="col-1 px-0 align-self-center"><input type="radio" className="form-control" id="cause_benefits" name="cancel_cause" onChange={this.changeCustomer} value={constClass.CAUSE.BENEFITS} checked={this.state.my_customer.cancel_cause === constClass.CAUSE.BENEFITS} /></div>
                <div className="col-10 px-0 align-self-center"><label className="my-0" htmlFor={"cause_benefits"}>{constClass.CAUSE_NAME.BENEFITS}</label></div>
              </div>
              <div className="row mx-0 px-3-env text-left">
                <div className="col-1 px-0 align-self-center" />
                <div className="col-1 px-0 align-self-center"><input type="radio" className="form-control" id="cause_usage" name="cancel_cause" onChange={this.changeCustomer} value={constClass.CAUSE.USAGE} checked={this.state.my_customer.cancel_cause === constClass.CAUSE.USAGE} /></div>
                <div className="col-10 px-0 align-self-center"><label className="my-0" htmlFor={"cause_usage"}>{constClass.CAUSE_NAME.USAGE}</label></div>
              </div>
              <div className="row mx-0 px-3-env text-left">
                <div className="col-1 px-0 align-self-center" />
                <div className="col-1 px-0 align-self-center"><input type="radio" className="form-control" id="cause_necessary" name="cancel_cause" onChange={this.changeCustomer} value={constClass.CAUSE.NECESSARY} checked={this.state.my_customer.cancel_cause === constClass.CAUSE.NECESSARY} /></div>
                <div className="col-10 px-0 align-self-center"><label className="my-0" htmlFor={"cause_necessary"}>{constClass.CAUSE_NAME.NECESSARY}</label></div>
              </div>
              <div className="row mx-0 px-3-env text-left">
                <div className="col-1 px-0 align-self-center" />
                <div className="col-1 px-0 align-self-center"><input type="radio" className="form-control" id="cause_similar" name="cancel_cause" onChange={this.changeCustomer} value={constClass.CAUSE.SIMILAR} checked={this.state.my_customer.cancel_cause === constClass.CAUSE.SIMILAR} /></div>
                <div className="col-10 px-0 align-self-center"><label className="my-0" htmlFor={"cause_similar"}>{constClass.CAUSE_NAME.SIMILAR}</label></div>
              </div>
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col-1 px-0 align-self-center" />
                <div className="col-1 px-0 align-self-center"><input type="radio" className="form-control" id="cause_other" name="cancel_cause" onChange={this.changeCustomer} value={constClass.CAUSE.OTHER} checked={this.state.my_customer.cancel_cause === constClass.CAUSE.OTHER} /></div>
                <div className="col-10 px-0 align-self-center"><label className="my-0" htmlFor={"cause_other"}>{constClass.CAUSE_NAME.OTHER}</label></div>
              </div>
              <div className="row mx-0 px-3-env text-left">
                <div className="col px-0 align-self-center">
                  <span>今後もサービス向上の為内容を充実すると考えています。<br />
                    それでも退会されますか？</span>
                </div>
              </div>
              <div className="row mx-0 px-3-env pt-2 pb-2">
                <div className="col-12 text-center p-0">
                  <button
                    disabled={this.state.disabled}
                    className="btn-lg btn-active w-100 py-2"
                    onClick={() => { this.setState({ modal_flag: false }) }}>
                    継続利用する
                  </button>
                </div>
              </div>
              <div className="row mx-0 px-3-env pt-1 pb-3">
                <div className="col-12 text-center p-0">
                  <button
                    disabled={this.state.disabled}
                    className="btn btn-link"
                    onClick={() => { this.submitCancel() }}>
                    <u>退会する</u>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={(this.state.setting && (this.state.setting[constClass.SETTING.MODAL_NOTICE] || this.state.setting[constClass.SETTING.MODAL_NOTICE2]) && this.state.pre_modal_flag)} style={this.modalStyle} onRequestClose={() => this.setState({ pre_modal_flag: false })}>
            <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0 align-self-center">
                  <span>お知らせ</span>
                </div>
                <div className="col-auto px-0 align-self-start">
                  <div className="text-right px-0" onClick={() => this.setState({ pre_modal_flag: false })}><img className="icon" src={CloseImg} alt="閉じる" /></div>
                </div>
              </div>
              {(this.state.setting && this.state.setting[constClass.SETTING.MODAL_NOTICE]) &&
                <div className="row mx-0 px-3-env mt-3 mb-2 text-left">
                  <div className="col px-0 align-self-center">{Common.getBrString(this.state.setting[constClass.SETTING.MODAL_NOTICE])}</div>
                </div>
              }
              {(this.state.setting && this.state.setting[constClass.SETTING.MODAL_NOTICE2]) &&
                <div className="row mx-0 px-3-env mt-2 mb-3 text-left">
                  <div className="col px-0 align-self-center">{Common.getBrString(this.state.setting[constClass.SETTING.MODAL_NOTICE2])}</div>
                </div>
              }
              <div className="row mx-0 px-3-env pt-1 pb-3">
                <div className="col-12 text-center p-0">
                  <button
                    className="btn btn-link text-body"
                    onClick={() => this.setState({ pre_modal_flag: false })}>
                    閉じる
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={(this.state.cancel_modal_flag)} style={this.modalStyle}>
            <div className="container-fluid section-white m-0 py-2 vh-50 overflow-auto">
              <div className="row mx-0 px-3-env text-left form-group">
                <div className="col px-0 align-self-center">
                  <span>アカウント停止のお知らせ</span>
                </div>
              </div>
              <div className="row mx-0 px-3-env mt-3 mb-2 text-left">
                <div className="col px-0 align-self-center">お客様のアカウントは停止されました。</div>
              </div>
              <div className="row mx-0 px-3-env mt-2 mb-3 text-left">
                <div className="col px-0 align-self-center"></div>
              </div>
            </div>
          </Modal>
        </div>
          : this.state.siteId ? <Loading /> : <Loading />}
      </React.Fragment>
    )
  }
}

export default withRouter(Liff);