import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import Common from '../Common/common';

const CustomerDetail = (props) => {
  const { id, siteId } = useParams();
  const [campaignData, setCampaignData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [couponData, setCouponData] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [customerDetailMaster, setCustomerDetailMaster] = useState(null);
  const history = useHistory();
  const { setting } = props;

  const refreshData = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    try {
      const campaign_points = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/campaignpoints/${siteId}/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      setCampaignData(campaign_points);
      const customer = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customer/${siteId}/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      const coupon = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/coupon/${siteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      setCouponData(coupon);
      const ticket = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket/${siteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      setTicketData(ticket);
      // お客様詳細マスタ
      const master = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/customerdetail/${siteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      setCustomerDetailMaster(master);

      // チケット利用データを整形
      const temp_issue = customer.t_ticket_issues ? customer.t_ticket_issues : [];
      const temp_use = customer.t_ticket_uses ? customer.t_ticket_uses : [];
      const ticketUse = temp_issue.concat(temp_use.map((u) => ({ 
        ...u, issue_count: u.ticket_times, limit_date: ticket.find(t => t.ticket_id === u.ticket_id) ? ticket.find(t => t.ticket_id === u.ticket_id).ticket_end_date : null 
      })));;
      customer.ticketUse = ticketUse.sort((a, b) => a.ticket_id === b.ticket_id ? a.issue_count - b.issue_count : a.ticket_id - b.ticket_id);
      setCustomerData(customer);

    } catch (err) {
      console.log(err);
    }
  }, [siteId, id]);

  useEffect(() => {
    refreshData();
  }, [refreshData]); //


  const renderCampaignList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
        {(Common.getFunctionEnabled(setting, 'CAMPAIGN')) &&
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  キャンペーンID
                </td>
                <td className="text-center align-middle">
                  キャンペーン名
                </td>
                <td className="text-center align-middle">
                  獲得ポイント
                </td>
              </tr>
            </thead>
            <tbody>
              {campaignData.sort((a, b) => a.campaign_id > b.campaign_id ? -1 : 1).map((data, idx) => (
                <tr key={data.campaign_id}>
                  {/* ID */}
                  <td className="text-center align-middle">
                    {data.campaign_id}
                  </td>
                  {/* キャンペーン名 */}
                  <td className="text-center align-middle">
                    {data.campaign_name}
                  </td>
                  {/* 獲得ポイント */}
                  <td className="text-center align-middle">
                    {data.sum_point}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        }   
        </div>
      </div>
    );
  }

  const renderCouponList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
        {(Common.getFunctionEnabled(setting, 'COUPON')) &&
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  クーポンID
                </td>
                <td className="text-center align-middle">
                  クーポン名
                </td>
                <td className="text-center align-middle">
                  利用年月
                </td>
                <td className="text-center align-middle">
                  クーポンコード
                </td>
                <td className="text-center align-middle">
                  使用日時
                </td>
              </tr>
            </thead>
            <tbody>
              {customerData.m_coupon_codes.sort((a, b) => a.use_date > b.use_date ? -1 : 1).map((data, idx) => (
                <tr key={data.coupon_id + '_' + data.coupon_month}>
                  {/* ID */}
                  <td className="text-center align-middle">
                    {data.coupon_id}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {couponData.find(c => c.coupon_id === data.coupon_id).coupon_name}
                  </td>
                  {/* 利用年月 */}
                  <td className="text-center align-middle">
                    {data.coupon_month}
                  </td>
                  {/* クーポンコード */}
                  <td className="text-center align-middle">
                    {data.coupon_code}
                  </td>
                  {/* 使用日時 */}
                  <td className="text-center align-middle">
                    {data.use_date ? moment(data.use_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        }
        </div>
      </div>
    );
  }

  const renderTicketList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-12 p-0">
        {( Common.getFunctionEnabled(setting, 'TICKET')) &&
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  クーポンID
                </td>
                <td className="text-center align-middle">
                  クーポン名
                </td>
                <td className="text-center align-middle">
                  連番
                </td>
                <td className="text-center align-middle">
                  有効期限
                </td>
                <td className="text-center align-middle">
                  取得日
                </td>
                <td className="text-center align-middle">
                  使用日時
                </td>
                <td className="text-center align-middle">
                  無効
                </td>
                <td className="text-center align-middle">
                  限定発行フラグ
                </td>
                <td className="text-center align-middle">
                  自動発行フラグ
                </td>
                <td className="text-center align-middle">
                  初回発行フラグ
                </td>
              </tr>
            </thead>
            <tbody>
              {customerData.ticketUse.map((data, idx) => (
                <tr key={data.ticket_id + '_' + data.issue_count}>
                  {/* ID */}
                  <td className="text-center align-middle">
                    {data.ticket_id}
                  </td>
                  {/* チケット名 */}
                  <td className="text-center align-middle">
                    {ticketData.find(t => t.ticket_id === data.ticket_id) ? ticketData.find(t => t.ticket_id === data.ticket_id).ticket_name : ''}
                  </td>
                  {/* 連番 */}
                  <td className="text-center align-middle">
                    {data.issue_count}
                  </td>
                  {/* 有効期限 */}
                  <td className="text-center align-middle">
                    {data.limit_date}
                  </td>
                  {/* 取得日 */}
                  <td className="text-center align-middle">
                    {data.ins_date ? moment(data.ins_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                  </td>
                  {/* 使用日時 */}
                  <td className="text-center align-middle">
                    {data.use_date ? moment(data.use_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                  </td>
                  {/* 無効フラグ */}
                  <td className="text-center align-middle">
                    {data.invalid_flag === constClass.INVALID_FLAG.INVALID ? constClass.INVALID_FLAG_NAME.INVALID : ''}
                    {data.invalid_date ? moment(data.invalid_date).format(" (YYYY/MM/DD HH:mm:ss)") : ''}
                  </td>
                  {/* 限定発行フラグ */}
                  <td className="text-center align-middle">
                    {ticketData.find(t => t.ticket_id === data.ticket_id).limit_issue_flag === constClass.FLAG.ON ? '〇' : '×'}
                  </td>
                  {/* 自動発行フラグ */}
                  <td className="text-center align-middle">
                    {ticketData.find(t => t.ticket_id === data.ticket_id).auto_issue_flag === constClass.FLAG.ON ? '〇' : '×'}
                  </td>
                  {/* 初回発行フラグ */}
                  <td className="text-center align-middle">
                    {ticketData.find(t => t.ticket_id === data.ticket_id).first_issue_flag === constClass.FLAG.ON ? '〇' : '×'}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        }
        </div>
      </div>
    );
  }

  const renderPaymentHistory = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-8 p-0">
        {(setting && setting['PAYMENT_REQUIRED'] === constClass.FLAG.ON) &&
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  決済年月
                </td>
                <td className="text-center align-middle">
                  決済日時
                </td>
                <td className="text-center align-middle">
                  取引ID
                </td>
                <td className="text-center align-middle">
                  処理区分
                </td>
              </tr>
            </thead>
            <tbody>
              {customerData.t_payment_histories.sort((a, b) => a.transaction_date > b.transaction_date ? 1 : -1).map((data, idx) => (
                <tr key={data.history_no}>
                  {/* 決済年月 */}
                  <td className="text-center align-middle">
                    {data.month}
                  </td>
                  {/* 決済日時 */}
                  <td className="text-center align-middle">
                    {data.transaction_date ? moment(data.transaction_date).format("YYYY/MM/DD HH:mm:ss") : ''}
                  </td>
                  {/* 取引ID */}
                  <td className="text-center align-middle">
                    {data.transaction_id}
                  </td>
                  {/* 取引ID */}
                  <td className="text-center align-middle">
                    {constClass.JOB_CD_NAME[data.job_cd]}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        }
        </div>
      </div>
    );
  }

  const renderCustomerDetail = () => {
    return (
      <div className="row mx-0 my-2 px-1-env">
        <div className="col">
          <div className="row my-1">
            <div className="col-auto text-left">
              <button type="button"
                className={`btn btn-secondary mx-1 w-100`}
                onClick={() => history.goBack()}>
                戻る
              </button>
            </div>
            <div className="col">
              <div className="row my-2">
              {Common.inputList(setting, customerDetailMaster).filter(p => p.entry_type_short_name !== "生年月日(FromTo)").map((data, idx) => {
                return renderData(customerData, data, idx)
              })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderData = (data, entry, idx) => {
    var value = null;
    const detail = entry.detail_data.find(() => true);
    const record = entry.data ? Common.getCustomerDetailObject(data[entry.data]) : data;
    if (detail.input_type === 'radio') {
      value = entry.detail_data.find(d => d.entry_data === record[entry.entry_type]) ? entry.detail_data.find(d => d.entry_data === record[entry.entry_type]).entry_name : '';
    } else if (detail.input_type === 'datetime') {
      value =  moment(record[entry.entry_type]).isValid() ? moment(record[entry.entry_type]).format("YYYY/MM/DD HH:mm:ss") : '';
    } else if (detail.input_type === 'date') {
      value = Common.getDateObject(record[entry.entry_type]).str;
    } else if (detail.input_type === 'date-y') {
      // ymd付きが指定された場合はdetail.entry_typeから値を取得
      value = Common.getDateObject(record[detail.entry_type]).year || '';
    } else if (detail.input_type === 'date-m') {
      value = Common.getDateObject(record[detail.entry_type]).month || '';
    } else if (detail.input_type === 'date-d') {
      value = Common.getDateObject(record[detail.entry_type]).date || '';
    } else {
      value = record[entry.entry_type];
    }
    return (
      <div className="col-4" key={idx}>
        <div className="row h-100">
          <div className="col-auto text-center align-self-center">
            <span>{entry.entry_type_short_name}</span>
          </div>
          <div className="col text-left align-self-center">
            {value}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      {(customerData === null || couponData === null || ticketData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {(customerData !== null) && (renderCustomerDetail())}
      {(campaignData !== null) && (renderCampaignList())}
      {(customerData !== null && couponData !== null) && (renderCouponList())}
      {(customerData !== null && ticketData !== null) && (renderTicketList())}
      {(customerData !== null) && (renderPaymentHistory())}
    </div>
  )
}

export default CustomerDetail;