import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
// import util from 'util';
import constClass from '../../Constants/Constants';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import TicketList from '../Liff/TicketList';
import LiffTicket from '../Liff/Ticket';
import moment from 'moment';
import Encoding from 'encoding-japanese';
import Modal from 'react-modal';
import Papa from "papaparse";

const Ticket = (props) => {
  const { user, setting } = props;
  const { siteId } = useParams();
  const [ticketData, setTicketData] = useState(null);
  const [ticketIssueData, setTicketIssueData] = useState(null);
  const [ticketIssueCsvData, setTicketIssueCsvData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const { addToast } = useToasts();
  const [importDisplayData, setImportDisplayData] = useState(null);
  const [importData, setImportData] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [editFlag, seteditFlag] = useState(false);
  const scrollRef = useRef();
  const imageRef = useRef();

  const [values, setValues] = useState({
    ticket_id: null,
    ticket_name: '',
    ticket_summary: '',
    ticket_detail: '',
    ticket_image_url: '',
    ticket_notes: '',
    hp_url: '',
    ticket_start_month: moment().format('YYYYMM'),
    ticket_end_month: moment().format('YYYYMM'),
    ticket_start_date: moment().format('YYYYMMDD'),
    ticket_end_date: moment().format('YYYYMMDD'),
    ticket_times: 0,
    use_point: 0,
    reset_flag: constClass.FLAG.OFF,
    auto_issue_flag: constClass.FLAG.OFF,
    limit_issue_flag: constClass.FLAG.OFF,
    first_issue_flag: constClass.FLAG.OFF,
    ticket_display_day: 10,
    uploadfile: null,
    uploadfiletype: null,
    deletefile: null,
    first_come_served: 0,
    target_value: 0
  });

  const modalConfirmStyle = {
    overlay: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      left: '10%',
      right: '10%',
      top: '15%',
      bottom: 'auto',
      padding: "0px",
      maxHeight: "80%",
      width: "80%",
      overflowX: "hidden",
      pointerEvents: "auto",
      backgroundClip: "padding-box",
      border: "1px rgba(0,0,0,.2)",
      overflow: "visible",
    }
  };

  const [pdfValues, setPdfValues] = useState({
    ticket_id: null,
    pdf_url: '',
    pdfuploadfile: null,
    pdfuploadfiletype: null,
    pdfdeletefile: null
  });

  const refreshData = useCallback(async () => {
    seteditFlag(false)
    const jwt = localStorage.getItem('jwt');
    const ticketData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setTicketData(ticketData);
    const ticketIssueData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setTicketIssueData(ticketIssueData);
    const ticketIssueCsvData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue_csv/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    setTicketIssueCsvData(ticketIssueCsvData);
  }, [siteId]);

  /* 編集ボタン押下 */
  const handleUpdateClick = (data) => {
    seteditFlag(false);
    const temp = data;
    temp.deletefile = null;
    temp.uploadfiletype = null;
    if (data.ticket_image_url != null) temp.uploadfile = `/${siteId}/${constClass.TICKET_IMAGE_DIR + data.ticket_image_url}`;
    else temp.uploadfile = null;
    temp.ticket_summary = temp.ticket_summary ? temp.ticket_summary : '';
    temp.ticket_detail = temp.ticket_detail ? temp.ticket_detail : '';
    temp.hp_url = temp.hp_url ? temp.hp_url : '';
    temp.ticket_notes = temp.ticket_notes ? temp.ticket_notes : '';
    temp.ticket_start_month = temp.ticket_start_month ? temp.ticket_start_month : moment().format('YYYYMM');
    temp.ticket_end_month = temp.ticket_end_month ? temp.ticket_end_month : '999912';
    temp.ticket_start_date = temp.ticket_start_date ? temp.ticket_start_date : moment().format('YYYYMMDD');
    temp.ticket_end_date = temp.ticket_end_date ? temp.ticket_end_date : moment().format('YYYYMMDD');
    temp.use_point = (temp.use_point !== null || temp.use_point !== undefined) ? temp.use_point : 1;
    temp.ticket_times = (temp.ticket_times !== null || temp.ticket_times !== undefined) ? temp.ticket_times : 1;
    temp.reset_flag = temp.reset_flag ? constClass.FLAG.OFF : constClass.FLAG.OFF;
    temp.auto_issue_flag = data.auto_issue_flag;
    temp.limit_issue_flag = data.limit_issue_flag;
    temp.first_issue_flag = data.first_issue_flag;
    temp.ticket_display_day = (temp.ticket_display_day !== null || temp.ticket_display_day !== undefined) ? temp.ticket_display_day : 10;
    temp.first_come_served = (temp.first_come_served !== null || temp.first_come_served !== undefined) ? temp.first_come_served : 0;
    temp.target_value = (temp.target_value !== null || temp.target_value !== undefined) ? temp.target_value : 0;
    setValues(temp);
    setPdfValues({
      ticket_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  /* RESETボタン押下 */
  const handleResetClick = () => {
    setValues({
      ticket_id: null,
      ticket_name: '',
      ticket_summary: '',
      ticket_detail: '',
      ticket_image_url: '',
      ticket_notes: '',
      hp_url: '',
      ticket_start_month: moment().format('YYYYMM'),
      ticket_end_month: '999912',
      ticket_times: 0,
      reset_flag: constClass.FLAG.OFF,
      uploadfile: null,
      uploadfiletype: null,
      deletefile: null,
      ticket_start_date: moment().format('YYYYMMDD'),
      ticket_end_date: moment().format('YYYYMMDD'),
      use_point: 0,
      ticket_display_day: 10,
      auto_issue_flag: constClass.FLAG.OFF,
      limit_issue_flag: constClass.FLAG.OFF,
      first_issue_flag: constClass.FLAG.OFF,
      first_come_served: 0,
      target_value: 0,
    });
    setPdfValues({
      ticket_id: null,
      pdf_url: '',
      pdfuploadfile: null,
      pdfuploadfiletype: null,
      pdfdeletefile: null
    });
  }

  const handleButtonClick = () => {
    handleResetClick()
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  // ファイルdata
  const handleFile = async (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      const codes = new Uint8Array(reader.result);
      const encoding = Encoding.detect(codes);
      const csv = Encoding.convert(codes, {
        to: 'UNICODE',
        from: encoding,
        type: 'string',
      });
      var List = []; // 登録データ
      var ErrBox = []; // エラーデータ
      var check = null; // チェックデータ
      var errflag = false; // エラーフラグ
      var dataflag = false; // データフラグ
      var filename = value.name; //ファイル名

      if (filename.slice(filename.lastIndexOf('.') + 1) !== "csv" && filename.slice(filename.lastIndexOf('.') + 1) !== "txt") {
        window.alert("csv形式のファイルをアップロードしてください");
        return;
      }

      const imp = Papa.parse(csv, { skipEmptyLines: true }).data;
      if (['line_id', 'lineid', 'Line_id', 'line_Id', 'LINE_ID', 'Line_Id'].includes(imp[0][0])) {
        imp.shift(); // １行目を削除
      }

      try {
        imp.forEach((row, rowIdx) => {
          check = {
            "line_id": row[0].replace(/"/g, '').trim(),
            "ticket_id": values.ticket_id,
            "limit_date": moment(values.ticket_end_date).format('YYYY-MM-DD'),
          };

          // データチェック
          if (!check.line_id) {
            errflag = true;
            ErrBox.push(rowIdx + 1 + '行目：LINEIDを入力してください');
          }
          dataflag = true;
          if (errflag === false) {
            List.push(check);
          }
        });
      } catch {
        window.alert("ファイルのデータ内容が不正です");
        return;
      }

      // エラーメッセージ
      if (dataflag === false) {
        ErrBox.push("取り込むデータがありません");
        window.alert("取り込むデータがありません");
      }
      if (errflag === true) {
        window.alert("取込エラー");
      }

      setImportDisplayData(imp);
      setImportData(List);
      setErrMessage(ErrBox);
    }
    reader.readAsArrayBuffer(value);
  }

  // CSVデータインポート
  const clearImportData = () => {
    setImportDisplayData(null);
    setImportData(null);
    setErrMessage(null);
    setConfirm(false);
  }

  // CSV戻るボタン
  const handleBackClick = () => {
    setImportDisplayData(null);
    setImportData(null);
    setErrMessage(null);
  }

  // CSVデータ反映：処理
  const handleExecClick = async () => {
    const jwt = localStorage.getItem('jwt');

    let res;
    setLockData(true);
    var import_data = [];

    try {
      const ticketIdData = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ticket/${siteId}/${values.ticket_id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })).data;
      importData.forEach((data) => {
        var add_data = {
          site_id: props.match.params.siteId,
          ticket_id: data.ticket_id,
          line_id: data.line_id,
          limit_date: ticketIdData.ticket_end_date,
          ins_name: user.userId,
          upd_name: user.userId,
        };
        import_data.push(add_data);
      });
    } catch (err) {
      console.log(err);
    }


    try {
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue_csv/import/${siteId}`, import_data, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.message, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        setErrMessage(res.data.box);
        await refreshData();
      }
      setLockData(false);
      //userActions.logout();
    }
  }

  /* DB更新 */
  const handleRegistClick = async (data) => {
    if (data.auto_issue_flag === constClass.FLAG.ON || data.first_issue_flag === constClass.FLAG.ON) {
      data.limit_issue_flag = constClass.FLAG.ON
    }
    console.log(data);
    const jwt = localStorage.getItem('jwt');
    if (!data.ticket_name) {
      window.alert('クーポン名を入力してください。');
      return;
    }
    // 入力チェック
    if (!data.ticket_start_date) {
      window.alert('利用開始日を入力してください。');
      return;
    }
    if (!data.ticket_end_date) {
      window.alert('利用終了日を入力してください');
      return;
    }
    if (data.ticket_times === "") {
      data.ticket_times = 0;
    }
    if (!data.use_point) {
      data.use_point = 0;
    }
    if (!data.ticket_display_day) {
      data.ticket_display_day = 0;
    }
    if (!data.first_come_served) {
      data.first_come_served = 0;
    }
    if (!data.target_value) {
      data.target_value = 0;
    }
    if (data.ticket_start_date.replace(/-/g, "") > data.ticket_end_date.replace(/-/g, "")) {
      window.alert('利用開始日と利用終了日が逆転しています。');
      return;
    }
    const dateCoveredAutoTicket = ticketData.filter(x =>
      x.auto_issue_flag === constClass.FLAG.ON && x.ticket_id !== data.ticket_id &&
      (x.ticket_start_date.replace(/-/g, "") <= data.ticket_end_date.replace(/-/g, "") && x.ticket_end_date.replace(/-/g, "") >= data.ticket_start_date.replace(/-/g, ""))
    );
    if (data.auto_issue_flag === constClass.FLAG.ON && dateCoveredAutoTicket.length > 0) {
      const dateCoveredTicketId = dateCoveredAutoTicket.map(ticket => ticket.ticket_id).join(', ');
      window.alert(`入力した利用開始日と利用終了日の期間と重複し、自動発行フラグがONのデータが存在します。\n該当するチケットID: ${dateCoveredTicketId}`);
      return;
    }
    const dateCoveredFirstTicket = ticketData.filter(x =>
      x.first_issue_flag === constClass.FLAG.ON && x.ticket_id !== data.ticket_id &&
      (x.ticket_start_date.replace(/-/g, "") <= data.ticket_end_date.replace(/-/g, "") && x.ticket_end_date.replace(/-/g, "") >= data.ticket_start_date.replace(/-/g, ""))
    );
    if (data.first_issue_flag  === constClass.FLAG.ON && dateCoveredFirstTicket.length > 0) {
      const dateCoveredTicketId = dateCoveredFirstTicket.map(ticket => ticket.ticket_id).join(', ');
      window.alert(`入力した利用開始日と利用終了日の期間と重複し、初回発行フラグがONのデータが存在します。\n該当するチケットID: ${dateCoveredTicketId}`);
      return;
    }
    if (data.auto_issue_flag !== constClass.FLAG.ON) {
      data.use_point = 0;
    }
    if (data.auto_issue_flag === constClass.FLAG.ON || data.limit_issue_flag === constClass.FLAG.ON || data.first_issue_flag === constClass.FLAG.ON ) {
      data.ticket_times = 0;
    }
    if (data.auto_issue_flag === constClass.FLAG.ON || data.first_issue_flag === constClass.FLAG.ON ) {
      data.first_come_served = 0;
    }
    var params = data;
    if (data.uploadfiletype === null) data.uploadfile = null;
    params.ins_name = user.userId;
    params.upd_name = user.userId;
    params.site_id = siteId;

    if (data.uploadfile && imageRef.current) {
      var width = imageRef.current.width;
      var height = imageRef.current.height;
      if (width > 100000) {
        width = 100000;
      }
      if (height > 100000) {
        height = 100000;
      }
      var image_ratio = width + ':' + height;
      if ((height/width) > 3) {
        image_ratio = '1:3';
      }
      data.image_ratio = image_ratio;
    }

    let res;
    try {
      setLockData(true);
      if (params.ticket_id === null || params.ticket_id === "") {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      } else {

        res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/ticket/${params.ticket_id}`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }
  /* DB削除 */
  const handleDeleteClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    if (!window.confirm(`クーポン[${data.ticket_id}:${data.ticket_name}]を削除します。よろしいですか？`)) {
      return;
    }
    const params = {
      site_id: siteId,
      ticket_id: data.ticket_id,
      upd_date: data.upd_date
    };
    let res;
    try {
      setLockData(true);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* PDFファイルアップロード */
  const handlePdfUploadClick = async (data) => {
    const jwt = localStorage.getItem('jwt');
    if (!data.pdfuploadfiledata) {
      if (data.pdfdeletefile) {
        if (!window.confirm('PDFファイルを削除してもよろしいですか？')) {
          return;
        }
      } else {
        window.alert('ファイルを選択してください。');
        return;
      }
    }

    let res;
    try {
      setLockData(true);
      const form = new FormData();
      if (data.pdfuploadfiletype) form.append("uploadfile", data.pdfuploadfile);
      form.append("site_id", siteId);
      form.append("pdf_url", data.pdf_url);
      form.append("deletefile", data.pdfdeletefile);
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/pdf_upload/${data.ticket_id}`, form, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'multipart/form-data',
        }
      });

    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      if (!res) {
      } else if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshData();
      }
      setLockData(false);
      handleResetClick();
    }
  }

  /* PDFボタン押下 */
  const handlePdfClick = (data) => {
    const temp = data;
    temp.pdfdeletefile = null;
    temp.pdfuploadfiletype = null;
    if (data.pdf_url != null) temp.pdfuploadfile = `/${siteId}/${constClass.TICKET_PDF_DIR + data.pdf_url}`;
    else temp.pdfuploadfile = null;

    setPdfValues(temp);
  }

  const renderUpdateButton = (data) => {
    return (
      <button
        className={`btn btn-primary mx-1`}
        onClick={() => handleUpdateClick(data)}>
        編集
      </button>
    )
  }

  const renderRegistButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_name === ''}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleRegistClick(data)}>
        登録
      </button>
    )
  }

  const renderDeleteButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_id === null}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handleDeleteClick(data)}>
        削除
      </button>
    )
  }

  const renderResetButton = () => {
    return (
      <button type="button"
        disabled={false}
        className={`btn btn-primary mx-1 w-60`}
        onClick={() => handleResetClick()}>
        リセット
      </button>
    )
  }

  const renderPdfButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_id === null}
        className={`btn btn-primary mx-1 w-60`}
        onClick={() => handlePdfClick(data)}>
        PDF編集
      </button>
    )
  }

  const renderUploadButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || (!data.pdfuploadfiledata && !data.pdfdeletefile)}
        className={`btn btn-primary mx-1 w-50`}
        onClick={() => handlePdfUploadClick(data)}>
        アップロード
      </button>
    )
  }

  const renderCSVUploadButton = (data) => {
    return (
      <button type="button"
        disabled={lockData || data.ticket_id === null || data.ticket_end_date === null || data.ticket_end_date === '' || data.limit_issue_flag !== constClass.FLAG.ON || editFlag}
        className={`btn btn-primary mx-1 w-60`}
        onClick={() => setConfirm(true)}>
        CSVアップロード
      </button>
    )
  }

  const handleChange = (e) => {
    seteditFlag(true);
    const target = e.target;
    var value = target.type === "checkbox" ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    if ((name === "auto_issue_flag" && value === constClass.FLAG.ON) || (name === "first_issue_flag" && value === constClass.FLAG.ON)) {
      setValues({ ...values, limit_issue_flag: constClass.FLAG.ON });
    }
    if (name === "limit_issue_flag" && (values.auto_issue_flag === constClass.FLAG.ON || values.first_issue_flag === constClass.FLAG.ON)) {
      value = constClass.FLAG.ON;
    }
    setValues({ ...values, [name]: value });
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      var img = new Image();
      img.src = reader.result
      setValues({
        ...values,
        uploadfile: reader.result,
        uploadfiletype: value.type,
        deletefile: values.ticket_image_url
      });
    }
    reader.readAsDataURL(value);
  }

  const handleDeleteFile = () => {
    setValues({ ...values, uploadfile: null, uploadfiletype: null, deletefile: values.ticket_image_url });
  }

  const handlePdfChangeFile = (e) => {
    const value = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      setPdfValues({
        ...pdfValues,
        pdfuploadfile: value,
        pdfuploadfiletype: value.type,
        pdfdeletefile: pdfValues.pdf_url,
        pdfuploadfiledata: URL.createObjectURL(value),
      });
    }
    reader.readAsDataURL(value);
  }

  const handlePdfDeleteFile = () => {
    setPdfValues({ ...pdfValues, pdfuploadfile: null, pdfuploadfiletype: null, pdfdeletefile: pdfValues.pdf_url });
  }

  useEffect(() => {
    refreshData();
  }, [refreshData]); //

  const renderInput = () => {
    return (
      <div className="mx-1">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>クーポンID</span>
          </div>
          <div className="col-2 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_id" defaultValue={values.ticket_id} placeholder="新規" readOnly />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <span>クーポン名</span>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_name" value={values.ticket_name} onChange={handleChange} placeholder="クーポン名を入力してください" />
          </div>
        </div>
        {/* <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_detail">クーポン概要</label>
          </div>
          <div className="col-5 align-self-center">
            <input type="text" className="form-control" name="ticket_summary" id="ticket_summary" value={values.ticket_summary} placeholder="クーポンの概要(カテゴリ)を入力してください" onChange={handleChange}></input>
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_detail">クーポン詳細</label>
          </div>
          <div className="col-5 align-self-center">
            <textarea className="form-control" rows="3" name="ticket_detail" id="ticket_detail" value={values.ticket_detail} onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">クーポン画像</label>
          </div>
          <div className="col-5 align-self-center">
            {!(values.uploadfile) && <input type="file" className="form-control-file" name="ticketImage" id="ticketImage" value="" onChange={handleChangeFile} onClick={e => (e.target.value = null)} />}
            {values.uploadfile && <object id="uploadfileview" className="w-100" data={values.uploadfile} type={values.uploadfiletype}><img ref={imageRef} className="w-100" src={values.uploadfile} alt='uploadfile' /></object>}
            {values.uploadfile && <input type="button" className="form-controll" name="deletefile" id="deletefile" onClick={handleDeleteFile} value="ファイル削除" />}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_notes">注意事項</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <textarea className="form-control" rows="3" name="ticket_notes" id="ticket_notes" value={values.ticket_notes} onChange={handleChange}></textarea>
          </div>
          <div className="col-5 text-left align-self-center">
            リンクを追加する場合は、<span className="text-danger">&lt;#</span><span className="text-info">（表示する文字）</span><span className="text-danger">$</span><span className="text-success">（リンク先URL）</span><span className="text-danger">#&gt;</span>と記載してください<br />
            （例）<span className="text-danger">&lt;#</span><span className="text-info">予約はこちら</span><span className="text-danger">$</span><span className="text-success">https://example.com</span><span className="text-danger">#&gt;</span>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_start_date">利用開始日</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_start_date" value={values.ticket_start_date.replace(/-/g, "")} onChange={handleChange} placeholder="YYYYMMDD" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_end_date">利用終了日</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="ticket_end_date" value={values.ticket_end_date.replace(/-/g, "")} onChange={handleChange} placeholder="YYYYMMDD" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="hp_url">公式サイトURL</label>
          </div>
          <div className="col-5 text-center align-self-center">
            <input type="text" className="form-control" name="hp_url" value={values.hp_url} onChange={handleChange} placeholder="公式サイトへにリンクするURLを入力してください" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="ticket_times">利用回数</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="text" className="form-control" id="ticket_times" name="ticket_times" value={values.auto_issue_flag !== constClass.FLAG.ON && values.limit_issue_flag !== constClass.FLAG.ON && values.first_issue_flag !== constClass.FLAG.ON ? values.ticket_times : 0} onChange={handleChange} disabled={values.auto_issue_flag !== constClass.FLAG.ON && values.limit_issue_flag !== constClass.FLAG.ON && values.first_issue_flag !== constClass.FLAG.ON ? false : true}/>
          </div>
          <div className="col-5 text-left align-self-center">
            無制限に利用可能とする場合は「0」を入力してください
          </div>
        </div>
        {values.reset_flag === constClass.FLAG.ON &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="reset_flag">月度回数リセットフラグ</label>
            </div>
            <div className="col-5 text-left align-self-center">
              <input type="checkbox" className="form-control w-25" id="reset_flag" name="reset_flag" value="true" checked={values.reset_flag === constClass.FLAG.ON} onChange={handleChange}></input>
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="limit_issue_flag">限定発行フラグ</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="checkbox" className="form-control w-25" id="limit_issue_flag" name="limit_issue_flag" value="true" checked={(values.auto_issue_flag === constClass.FLAG.ON || values.first_issue_flag === constClass.FLAG.ON) ? (values.auto_issue_flag === constClass.FLAG.ON || values.first_issue_flag === constClass.FLAG.ON) : values.limit_issue_flag === constClass.FLAG.ON} onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="auto_issue_flag">自動発行フラグ</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="checkbox" className="form-control w-25" id="auto_issue_flag" name="auto_issue_flag" value="true" checked={values.auto_issue_flag === constClass.FLAG.ON} onChange={handleChange}></input>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="first_issue_flag">初回発行フラグ</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="checkbox" className="form-control w-25" id="first_issue_flag" name="first_issue_flag" value="true" checked={values.first_issue_flag === constClass.FLAG.ON} onChange={handleChange}></input>
          </div>
        </div>
        {(values.auto_issue_flag === constClass.FLAG.ON) &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="use_point">使用ポイント数</label>
            </div>
            <div className="col-5 text-left align-self-center">
              <input type="text" className="form-control" id="use_point" name="use_point" value={values.use_point} onChange={handleChange} />
            </div>
          </div>
        }
        {(values.auto_issue_flag === constClass.FLAG.ON || values.first_issue_flag === constClass.FLAG.ON) &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="ticket_display_day">クーポン取得からの有効期限日数</label>
            </div>
            <div className="col-5 text-left align-self-center">
              <input type="text" className="form-control" id="ticket_display_day" name="ticket_display_day" value={values.ticket_display_day} onChange={handleChange} />
            </div>
          </div>
        }
        {(values.auto_issue_flag !== constClass.FLAG.ON && values.first_issue_flag !== constClass.FLAG.ON) &&
          <div className="row mb-3">
            <div className="col-2 text-center align-self-center">
              <label htmlFor="first_come_served">先着何名</label>
            </div>
            <div className="col-5 text-left align-self-center">
              <input type="text" className="form-control" id="first_come_served" name="first_come_served" value={values.first_come_served} onChange={handleChange} />
            </div>
            <div className="col-5 text-left align-self-center">
              「0」を入力すると、先着何名様の制限がなくなります
            </div>
          </div>
        }
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="target_value">目標値</label>
          </div>
          <div className="col-5 text-left align-self-center">
            <input type="text" className="form-control" id="target_value" name="target_value" value={values.target_value} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-2 pb-4">
          <div className="col-3 text-center">
            {renderRegistButton(values)}
          </div>
          <div className="col-2 text-center">
            {renderDeleteButton(values)}
          </div>
          <div className="col-2 text-center">
            {renderResetButton(values)}
          </div>
          <div className="col-2 text-center d-none">
            {renderPdfButton(values)}
          </div>
          <div className="col-3 text-center">
            {renderCSVUploadButton(values)}
          </div>
          <Modal isOpen={confirm} style={modalConfirmStyle} onRequestClose={() => setConfirm(false)}>
            <div className="">
              <div className="modal-header">
                限定クーポンCSVインポート
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => clearImportData()}><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body">
                <div className="pb-1">
                  <span>クーポン発行数：{ticketIssueData ? ticketIssueData.filter(t => t.ticket_id === values.ticket_id).length :0}枚　アップロード数(クーポン発行待ち数)：{ticketIssueCsvData ? ticketIssueCsvData.filter(t => t.ticket_id === values.ticket_id).length : 0}枚</span>
                </div>
                {!importDisplayData && <input type="file" className="form-control-file" name="csv" id="csv" value="" accept=".csv" onChange={handleFile} onClick={e => (e.target.value = null)} />}
                {(importDisplayData && importDisplayData.length < 1) &&
                  <div>
                    <span>データが見つかりません。</span>
                    <button type="button" className="btn btn-secondary" onClick={() => clearImportData()}>戻る</button>
                  </div>
                }
                {(errMessage && errMessage.length > 0) &&
                  errMessage.map((data, dataIdx) =>
                    <div key={dataIdx} className="pl-4 text-danger">
                      <li>{data}</li>
                    </div>
                  )}
                {(importDisplayData && importDisplayData.length > 0) &&
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr className='text-nowrap bg-gray-alpha'>
                              <td className="text-center align-middle">
                                LINEID
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {importDisplayData.map((row, rowIdx) =>
                              <tr key={rowIdx}>
                                {row.map((data, idx) =>
                                  <td key={rowIdx + '_' + idx} className={`text-nowrap`}>{data.trim()}</td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <button type="button" className="btn btn-secondary" onClick={() => handleBackClick()}>戻る</button>
                      </div>
                      <div className="col-auto">
                        <button type="button" className="btn btn-primary" disabled={(errMessage && errMessage.length > 0)} onClick={() => handleExecClick()}>{(importDisplayData.length)}件のデータを反映</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => (setConfirm(false))}>Close</button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }

  const renderPdf = () => {
    return (
      <div className="mx-1 mb-4 border">
        <div className="row mb-3">
          <div className="col-2 text-center align-self-center">
            <label htmlFor="remarks">クーポンPDF</label>
          </div>
          <div className="col-5 align-self-center">
            {!(pdfValues.pdfuploadfile) && <input type="file" className="form-control-file" name="ticketPdf" id="ticketPdf" value="" onChange={handlePdfChangeFile} onClick={e => (e.target.value = null)} />}
            {pdfValues.pdfuploadfile && <object id="pdfuploadfileview" className="w-100" data={pdfValues.pdfuploadfiledata || pdfValues.pdfuploadfile} type={pdfValues.pdfuploadfiletype}>PDF</object>}
            {pdfValues.pdfuploadfile && <input type="button" className="form-controll" name="pdfdeletefile" id="pdfdeletefile" onClick={handlePdfDeleteFile} value="ファイル削除" />}
            {(!pdfValues.pdfuploadfiledata && pdfValues.pdfuploadfile) && <p>PDFのURL：<span>{process.env.REACT_APP_FRONTEND_URL}{pdfValues.pdfuploadfile}</span></p>}
          </div>
        </div>
        <div className="row mb-2 pb-2">
          <div className="col-3 text-center">
            {renderUploadButton(pdfValues)}
          </div>
        </div>
      </div>
    );
  }

  const renderList = () => {
    return (
      <div className="row mb-3 p-0">
        <div className="col-3 mb-1 p-0 text-left">
          <button type="button"
            className={`btn btn-primary mx-1 `}
            onClick={() => handleButtonClick()}
          >
            新規登録
          </button>
        </div>
        <div className="col-9 mb-1 p-0 text-right">
          <button type="button"
            disabled={lockData}
            className={`btn btn-primary mx-1 `}
            data-toggle="modal" data-target="#exampleModal"
          >
            プレビュー表示
          </button>
        </div>
        <div className="col-12 p-0">
          <table className="table table-bordered table-striped">
            <thead className={`table-info`}>
              <tr>
                <td className="text-center align-middle">
                  クーポンID
                </td>
                <td className="text-center align-middle">
                  クーポン名
                </td>
                <td className="text-center align-middle">
                  処理
                </td>
              </tr>
            </thead>
            <tbody>
              {ticketData.map((data, idx) => (
                <tr key={data.ticket_id} ref={idx === ticketData.length - 1 ? scrollRef : null}>
                  {/* クーポンID */}
                  <td className="text-center align-middle">
                    {data.ticket_id}
                  </td>
                  {/* クーポン名 */}
                  <td className="text-center align-middle">
                    {data.ticket_name}
                  </td>
                  {/* 処理 */}
                  <td className="text-center align-middle text-nowrap">
                    {renderUpdateButton(data)}
                  </td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  const renderPreview = () => {
    return (
      <div className="modal" id="exampleModal">
        <div className="modal-dialog preview-modal">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSelectData(null)}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              {(ticketData && !selectData) &&
                <TicketList
                  ticket_list={ticketData}
                  ticketClick={(id) => () => setSelectData({ ...ticketData.find(t => t.ticket_id === id), t_ticket_use: [] })}
                  siteId={siteId}
                  setting={setting}
                />
              }
              {selectData &&
                <LiffTicket
                  ticket={selectData}
                  ticketClick={(id) => () => { }}
                  backClick={() => setSelectData(null)}
                  disabled={lockData}
                  siteId={siteId}
                />
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setSelectData(null)}>Close</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">

      {ticketData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {ticketData !== null && (renderList())}
      {renderInput()}
      {pdfValues.ticket_id && renderPdf()}
      {renderPreview()}
    </div>
  )
}

export default Ticket;