import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Common from '../Common/common';
// import Popover from "react-popover";
// import CopyImg from '../Images/copy.svg';
import util from 'util';
import Quagga from "quagga";
import axios from 'axios';

const ReceiptScan = ({ coupon_codes, customerId, siteId, liffAccessToken, refresh, setting }) => {

  const [Message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [PermissionMessage, setPermissionMessage] = useState('');
  const [CouponMessage, setCouponMessage] = useState('');
  const [shopList, setShopList] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [input, setInput] = useState(null);
  const [deviceId, setDeviceId] = useState(null);

  const checkDevices = useCallback((devices) => {
    const normalDevices = devices.filter(
      (v) =>
        v.kind === "videoinput" && v.label.indexOf("camera2 0, facing back") >= 0
    );
    const videoDevices = devices.filter(
      (v) =>
        v.kind === "videoinput" && v.label.indexOf("facing back") >= 0
    );
    if (normalDevices.length > 0 && videoDevices.length > 0) {
      if (normalDevices[0].deviceId !== videoDevices[0].deviceId && normalDevices[0].deviceId !== deviceId) {
        setDeviceId(normalDevices[0].deviceId);
        return false;
      }
    }
    return true;
  }, [deviceId]);

  const initCamera = useCallback(() => {
    // QuaggaJs初期化のConfig
    const camera = document.querySelector("#container");
    camera.style.display = "block";

    const config = {
      inputStream: {
        type: "LiveStream",
        target: camera,
        constraints: {
          // MediaStreamConstraintsを参照
          audio: false,
          video: {
            facingMode: "environment",
          },
          width: 1920,
          height: 1080,
          deviceId,
        },
        area: { top: "30%", right: "0%", left: "0%", bottom: "30%" },
      },
      decoder: {
        // バーコードのデコーダー
        readers: ["codabar_reader"],
        multiple: false, // 同時に複数のバーコードを解析しない
      },
    };
    Quagga.init(
      //初期設定
      config,
      (err) => {
        if (err) {
          //権限がないときのエラー拾う
          setPermissionMessage(<>
            カメラへのアクセスが許可されていません、<br />
            画面を再度開きなおしてください。
          </>);
          console.log(err);
          return;
        }
        console.log("Initialization finished. Ready to start");
        if (deviceId) {
          Quagga.start();
        } else {
          if (Quagga.CameraAccess) {
            Quagga.CameraAccess.enumerateVideoDevices().then((devices) => {
              if (checkDevices(devices)) {
                Quagga.start();
              }
            })
              .catch((err) => {
                Quagga.start();
              });
          } else {
            Quagga.start();
          }
        }
      }
    );
  }, [deviceId, checkDevices]);

  const openCamera = useCallback(async () => {
    if (!PermissionMessage) {
      setMessage(null);
      setErrorMessage(null);
      setCouponMessage(null);

      const camera = document.querySelector("#container");
      camera.style.display = "block";

      if (!selectedShop) {
        return;
      }

      var code = null;
      var count = 0;
      //var isDetecting = false;

      Quagga.onDetected(async (result) => {
        // 3連続で同じコードを得られるまで検知
        if (code === result.codeResult.code && result.codeResult.code.length === 18) {
          count++;
        } else {
          // 前回のコードと違った場合
          count = 0;
          code = result.codeResult.code;
        }
        // 3連続で同じコードを得られた場合はカメラを停止
        if (count >= 3) {
          count = 0;
          code = null;
          console.log("読み取れたコード" + result.codeResult.code);
          // Quagga.stop();
          //onDetectedイベントリスナーを削除する
          Quagga.offDetected();
          camera.style.display = "none";

          const params = {
            site_id: siteId,
            line_id: liffAccessToken,
            purchase_date: result.codeResult.code.substring(1, 9),
            cashier_no: result.codeResult.code.substring(9, 13),
            receipt_no: result.codeResult.code.substring(13, 17),
            customer_id: customerId,
            shop_id: Number(selectedShop)
          };
          try {
            var receiptscan = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/receiptscan/line`, params);
            if (receiptscan.data.receipt) {
              setMessage("レシートの登録が完了しました。");
              if (receiptscan.data.ticket) {
                setCouponMessage("クーポンを獲得しました！");
              }
              // //ポイント付与
              // await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/${siteId}`, params);
              // ////有効ポイントの合計を取得
              // const point_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/available_sum/`, { site_id: siteId, customer_id: customerId, line_id: liffAccessToken })).data;
              // const point_info = point_data.find(c => c.customer_id === customerId);
              // var available_point = 0;
              // if (point_info !== undefined) {
              //   available_point = point_info.sum_point - point_info.sum_used_point;
              // }
              // setMessage("レシートの登録が完了しました。");
              // const ticket_data_res = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket/line/auto_issue/`, { site_id: siteId, line_id: liffAccessToken })).data;
              // const ticket_data = ticket_data_res.find(t => t.auto_issue_flag === constClass.FLAG.ON && t.use_point === available_point);
              // if (ticket_data) {
              //     try {
              //       await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ticket_issue/line/`, { site_id: siteId, ticket_id: ticket_data.ticket_id, customer_id: customerId, line_id: liffAccessToken });
              //       await axios.post(`${process.env.REACT_APP_BACKEND_URL}/points/line/${siteId}/use`, { site_id: siteId, usePoint: ticket_data.use_point, customer_id: customerId, line_id: liffAccessToken });
              //     } catch (err) {
              //       console.log(util.inspect(err));
              //       if (err.response && err.response.data) {
              //         setErrorMessage("エラーが発生しました。");
              //       } else {
              //         setErrorMessage("エラーが発生しました。");
              //       }
              //     }
              //   setCouponMessage("クーポンを獲得しました！");
              // }
            }
            else {
              if (receiptscan.data.message) {
                setErrorMessage(receiptscan.data.message);
              } else {
                setErrorMessage("エラーが発生しました。");
              }
            }
          } catch (err) {
            console.log(util.inspect(err));
            if (err.response && err.response.data) {
              setErrorMessage("エラーが発生しました。");
            } else {
              setErrorMessage("エラーが発生しました。");
            }
          }
        }
      });
    }
  }, [siteId, customerId, liffAccessToken, selectedShop, PermissionMessage]);

  const refreshData = useCallback(async () => {
    try {
      //店舗マスタを取得
      const shop_list_promise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/list/line/`, { site_id: siteId, line_id: liffAccessToken });
      //入力データを取得
      const input_promise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer_input/line/`, { site_id: siteId, line_id: liffAccessToken, customer_id: customerId });

      //店舗マスタを取得
      const shop_list = (await shop_list_promise).data;
      setShopList(shop_list);
      
      //入力データを取得
      const input_data = (await input_promise).data;
      if (input_data.receipt_scan && input_data.receipt_scan.shop_id) {
        const shopId = input_data.receipt_scan.shop_id;
        if (shop_list.find((s) => s.shop_id === Number(shopId))) {
          setSelectedShop(input_data.receipt_scan.shop_id);
        } else {
          setSelectedShop(shop_list.find(() => true) ? shop_list.find(() => true).shop_id : 0);
        }
      } else {
        setSelectedShop(shop_list.find(() => true) ? shop_list.find(() => true).shop_id : 0);
      }
      setInput(input_data);
    } catch (err) {
      console.log(err);
    }
  }, [siteId, liffAccessToken, customerId]);

  const changeSelectedShop = useCallback((e) => {
    const value = e.target.value;
    setSelectedShop(value);

    // 入力データを保存
    var data = input;
    data.receipt_scan = {
      shop_id: e.target.value
    };
    var params = {
      site_id: siteId,
      line_id: liffAccessToken,
      customer_id: customerId,
      input_data: data
    }
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/customer_input/line/update`, params);
  }, [siteId, liffAccessToken, customerId, input]);

  useEffect(() => {
    openCamera();

    //アンマウント時にonDetectedイベントリスナーを削除する
    return () => {
      Quagga.offDetected();
    };
  }, [openCamera]);

  useEffect(() => {
    initCamera();
    
    //アンマウント時に停止させる
    return () => {
      Quagga.stop();
    };
  }, [initCamera]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <div id="couponList" className="px-0-env">
      <div className="row mx-0">
        <div className="col mx-3 my-1 p-0 text-left bg-white">
          <div className="card-header p-2">
            {Common.getBrString(setting['RECEIPTSCAN_HEADER'])}
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col mx-3 my-0 py-1 px-0 text-left bg-white">
          <div className="px-2 pt-2">
            店舗選択
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col mx-3 my-0 py-1 px-0 text-left bg-white">
          <div className="px-2 pb-2">
            <select className="custom-select w-100 text-center" value={selectedShop || ''} onChange={changeSelectedShop} disabled={shopList.length < 2}>
              {shopList.map((shop) => 
                <option value={shop.shop_id} key={shop.shop_id}>{shop.shop_name}</option>
              )}
            </select>
          </div>
        </div>
      </div>
      <div className="row mx-0 mt-2">
        <div className="col mx-3 my-0 py-1 px-0 text-left bg-white">
          {!Message && !errorMessage && !PermissionMessage && <div className="text-center">読み込み中</div>}
          <div className="mt-2 px-2 py-0 text-center">
            {!PermissionMessage && 
              <div id="container" className="container">
                <div className="detect-area"></div>
                {/* カメラが表示される位置 */}
              </div>
            } 
            {errorMessage &&
              <div>
                <div className="row mx-0 mt-2">
                  <div className="col mx-3 my-0 px-0 text-left py-3">
                    <div className="text-center text-danger">{errorMessage}</div>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col mx-3 my-0 py-1 px-0 text-left pb-4">
                    <div className="px-2 py-2 text-center">
                      <button className="btn btn-active" onClick={() => openCamera()}>再読み込み</button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {Message && !errorMessage &&
              <div>
                <div className="row mx-0 mt-2">
                  <div className="col mx-3 my-0 px-0 text-left py-3">
                    <div className="text-center">{Message}</div>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col mx-3 my-0 py-1 px-0 text-left pb-4">
                    <div className="px-2 py-2 text-center">
                      <button className="btn btn-active" onClick={() => openCamera()}>次のレシートを読み込む</button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {CouponMessage &&
              <div>
                <div className="row mx-0">
                  <div className="col mx-3 my-0 py-1 px-0 text-left pt-4">
                    <div className="text-center text-danger">{CouponMessage}</div>
                  </div>
                </div>
                <div className="row mx-0">
                  <div className="col mx-3 my-0 py-1 px-0 text-left pb-4">
                    <div className="px-2 py-2 text-center">
                      <Link to={`/${siteId}/?page=${constClass.TICKETLIST}`} >
                        <button className="btn btn-active" onClick={() => refresh()}>クーポンを見る</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            }
            {PermissionMessage &&
              <div>
                <div className="row mx-0 mt-2">
                  <div className="col mx-3 my-0 px-0 text-left py-3">
                    <div className="text-center">{PermissionMessage}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceiptScan;