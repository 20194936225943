import React from 'react';
import constClass from '../../Constants/Constants';
import queryString from 'query-string';

const Common = {};

Common.getExternalUrl = function (url_string) {
  const url = new URL(url_string);
  if (url.protocol !== 'tel:') {
    url.searchParams.set('openExternalBrowser', '1');
  }
  return url.toString();
}

Common.getMonthString = function () {
  const date = new Date();
  return String(date.getFullYear()) + (('00' + String(date.getMonth() + 1)).slice(-2));
}

Common.getBrString = function (str) {
  const texts = (str ? str : '').split(/(\n)/).map((item, index) => {
    return (
      <React.Fragment key={index}>
        {item.match(/\n/) ? <br /> : this.getLinkedString(item)}
      </React.Fragment>
    );
  });
  return <div>{texts}</div>;
}

Common.getLinkedString = function (str) {
  // <#表記文字列$リンクURL#> を変換する
  const check = /<#[^$]+\$(?:https?:\/\/[\w/:%#$&?()~.=+-]+|tel:[\d-+()]+)#>/g; // <# $を含まない文字列 $ URLのパターン #>
  var matches = str.matchAll(check);
  var ret = [];
  var idx = 0; // 文字列の切り出し位置
  var key = 0; // reactエラー対応用
  for (const match of matches) {
    // 一致する前までを戻り値に書き足す
    ret.push(
      <React.Fragment key={key}>
        {this.getBoldString(str.substr(idx, match.index - idx))}
      </React.Fragment>
    );
    key += 1;

    // リンク付き文字列を書き足す
    const val = match[0].substr(2, match[0].length - 4); // <# #>を除いた文字列を取得
    const ary = val.split('$'); // $で分割 ary[0]:表記文字列 ary[1]:URL
    ret.push(
      <React.Fragment key={key}>
        <a href={this.getExternalUrl(ary[1])} target="_blank" rel="noreferrer" className="text-digitalcard">
          <u>{this.getBoldString(ary[0])}</u>
        </a>
      </React.Fragment>
    );
    key += 1;

    // 次の切り出し位置を更新
    idx = match.index + match[0].length;
  }
  // 切り出し位置から文字列の最後までを書き足す
  if (idx < str.length) {
    ret.push(
      <React.Fragment key={key}>
        {this.getBoldString(str.substr(idx, str.length - idx))}
      </React.Fragment>
    );
  }
  return ret;
}

Common.getBoldString = function (str) {
  // <##太字##> を変換する
  const check = /<##[^$]+##>/g; // <## $を含まない文字列 ##>
  // console.log(str);
  var matches = str.matchAll(check);
  var ret = [];
  var idx = 0; // 文字列の切り出し位置
  var key = 0; // reactエラー対応用
  for (const match of matches) {
    // 一致する前までを戻り値に書き足す
    ret.push(
      <React.Fragment key={key}>
        {str.substr(idx, match.index - idx)}
      </React.Fragment>
    );
    key += 1;

    // リンク付き文字列を書き足す
    const val = match[0].substr(3, match[0].length - 6); // <# #>を除いた文字列を取得
    ret.push(
      <React.Fragment key={key}>
        <b className="text-digitalcard">{val}</b>
      </React.Fragment>
    );
    key += 1;

    // 次の切り出し位置を更新
    idx = match.index + match[0].length;
  }
  // 切り出し位置から文字列の最後までを書き足す
  if (idx < str.length) {
    ret.push(
      <React.Fragment key={key}>
        {str.substr(idx, str.length - idx)}
      </React.Fragment>
    );
  }
  return ret;
}

Common.getFunctionEnabled = function (setting, function_name) {
  return (setting && setting['FUNC_' + function_name]) ? setting['FUNC_' + function_name] === '1' : false;
}

Common.getCustomerDetailObject = function (detail) {
  return detail ? detail.reduce((prev, d) => ({ ...prev, [d.entry_type]: d.entry_data }), {}) : {};
}

Common.setCustomerDetail = function (detail, entry_type, entry_data) {
  const ret = detail ? detail : [];
  if (detail.find(d => d.entry_type === entry_type)) {
    detail.find(d => d.entry_type === entry_type).entry_data = entry_data;
  } else {
    detail.push({ entry_type, entry_data });
  }
  return ret;
}

Common.getDateObject = function (value) {
  const str = value ? String(value) : '';
  const year = Number(str.slice(0, 4));
  const month = Number(str.slice(4, 6));
  const date = Number(str.slice(6, 8));
  const viewStr = (Number(str.slice(0, 4)) ? Number(str.slice(0, 4)) + '年' : '') + (Number(str.slice(4, 6)) ? Number(str.slice(4, 6)) + '月' : '') + (Number(str.slice(6, 8)) ? Number(str.slice(6, 8)) + '日' : '');
  return { year, month, date, str: viewStr };
}

Common.getDateObject1 = function (value) {
  const str = value ? String(value) : '';
  // const year = Number(str.slice(0, 4));
  const month = Number(str.slice(5, 7));
  const yearmonth = Number(str.slice(0, 4) + str.slice(5, 7));
  const viewStr = (Number(str.slice(0, 4)) ? Number(str.slice(0, 4)) + '年' : '') + (Number(str.slice(5, 7)) ? Number(str.slice(5, 7)) + '月' : '') + (Number(str.slice(8, 10)) ? Number(str.slice(8, 10)) + '日' : '');
  return { month, str: viewStr, yearmonth };
}

Common.inputList = (setting, master) => {
  const ret = [];
  if (!setting) {
    return [];
  }
  ret.push({ entry_type: 'customer_id', entry_type_short_name: 'お客様No', detail_data: [{ input_type: 'id', entry_type: 'customer_id', entry_seq: 1 }] });
  ret.push({ entry_type: 'line_id', entry_type_short_name: 'LINEID', detail_data: [{ input_type: 'text', entry_type: 'line_id', entry_seq: 1 }] });
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_CUSTOMER_NAME'])) {
    ret.push({ entry_type: 'customer_name', entry_type_short_name: setting['LABEL_CUSTOMER_NAME'], detail_data: [{ input_type: 'text', entry_type: 'customer_name', entry_seq: 1 }] });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_CUSTOMER_KANA'])) {
    ret.push({ entry_type: 'customer_kana', entry_type_short_name: setting['LABEL_CUSTOMER_KANA'], detail_data: [{ input_type: 'text', entry_type: 'customer_kana', entry_seq: 1 }] });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_GENDER'])) {
    ret.push({
      entry_type: 'gender', entry_type_short_name: setting['LABEL_GENDER'], detail_data: [
        { input_type: 'radio', entry_type: 'gender', entry_seq: 1, entry_data: constClass.GENDER.MAN, entry_name: constClass.GENDER_NAME.MAN },
        { input_type: 'radio', entry_type: 'gender', entry_seq: 2, entry_data: constClass.GENDER.WOMAN, entry_name: constClass.GENDER_NAME.WOMAN },
        { input_type: 'radio', entry_type: 'gender', entry_seq: 3, entry_data: constClass.GENDER.OTHER, entry_name: constClass.GENDER_NAME.OTHER },
      ]
    });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_BIRTH'])) {
    ret.push({
      entry_type: 'birth-y-m-d', entry_type_short_name: "生年月日(FromTo)", detail_data: [
        { input_type: 'date-y-m-d', entry_type: 'birth', entry_seq: 1 }
      ]
    });
    // entry.entry_typeにはymd付き、detail.entry_typeには本来のデータ型を設定
    if (setting['INPUT_BIRTH_YEAR'] === constClass.FLAG.ON) {
      ret.push({
        entry_type: 'birth-y', entry_type_short_name: setting['LABEL_BIRTH'] + '(年)', detail_data: [
          { input_type: 'date-y', entry_type: 'birth', entry_seq: 1 }
        ]
      });
    }
    if (setting['INPUT_BIRTH_MONTH'] === constClass.FLAG.ON) {
      ret.push({
        entry_type: 'birth-m', entry_type_short_name: setting['LABEL_BIRTH'] + '(月)', detail_data: [
          { input_type: 'date-m', entry_type: 'birth', entry_seq: 1 }
        ]
      });
    }
    if (setting['INPUT_BIRTH_DATE'] === constClass.FLAG.ON) {
      ret.push({
        entry_type: 'birth-d', entry_type_short_name: setting['LABEL_BIRTH'] + '(日)', detail_data: [
          { input_type: 'date-d', entry_type: 'birth', entry_seq: 1 }
        ]
      });
    }
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_PHONE_NUMBER'])) {
    ret.push({ entry_type: 'phone_number', entry_type_short_name: setting['LABEL_PHONE_NUMBER'], detail_data: [{ input_type: 'number', entry_type: 'phone_number', entry_seq: 1 }] });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_POSTAL_CODE'])) {
    ret.push({ entry_type: 'postal_code', entry_type_short_name: setting['LABEL_POSTAL_CODE'], detail_data: [{ input_type: 'number', entry_type: 'postal_code', entry_seq: 1 }] });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_ADDRESS'])) {
    ret.push({ entry_type: 'address', entry_type_short_name: setting['LABEL_ADDRESS'], detail_data: [{ input_type: 'text', entry_type: 'address', entry_seq: 1 }] });
  }
  if ([constClass.INPUT.ENABLED, constClass.INPUT.REQUIRED].includes(setting['INPUT_MAIL_ADDRESS'])) {
    ret.push({ entry_type: 'mail_address', entry_type_short_name: setting['LABEL_MAIL_ADDRESS'], detail_data: [{ input_type: 'text', entry_type: 'mail_address', entry_seq: 1 }] });
  }
  ret.push({ entry_type: 'card_id', entry_type_short_name: setting['LABEL_CARD_ID'] ? setting['LABEL_CARD_ID'] : '会員番号', detail_data: [{ input_type: 'text', entry_type: 'card_id', entry_seq: 1 }] });
  ret.push({ entry_type: 'register_date', entry_type_short_name: '登録日時', detail_data: [{ input_type: 'datetime', entry_type: 'register_date', entry_seq: 1 }] });
  ret.push({
    entry_type: 'status', entry_type_short_name: 'ステータス', detail_data: [
      { input_type: 'radio', entry_type: 'status', entry_seq: 1, entry_data: constClass.STATUS.UREG, entry_name: constClass.STATUS_NAME.UREG },
      { input_type: 'radio', entry_type: 'status', entry_seq: 2, entry_data: constClass.STATUS.REG, entry_name: constClass.STATUS_NAME.REG },
      { input_type: 'radio', entry_type: 'status', entry_seq: 3, entry_data: constClass.STATUS.CCL, entry_name: constClass.STATUS_NAME.CCL },
    ]
  });
  if (setting['PAYMENT_REQUIRED'] === constClass.FLAG.ON) {
    ret.push({ entry_type: 'payment_register_date', entry_type_short_name: '決済登録日時', detail_data: [{ input_type: 'datetime', entry_type: 'payment_register_date', entry_seq: 1 }] });
    ret.push({
      entry_type: 'payment_status', entry_type_short_name: '決済ステータス', detail_data: [
        { input_type: 'radio', entry_type: 'payment_status', entry_seq: 1, entry_data: constClass.PAYMENT_STATUS.UPAY, entry_name: constClass.PAYMENT_STATUS_NAME.UPAY },
        { input_type: 'radio', entry_type: 'payment_status', entry_seq: 2, entry_data: constClass.PAYMENT_STATUS.REG, entry_name: constClass.PAYMENT_STATUS_NAME.REG },
        { input_type: 'radio', entry_type: 'payment_status', entry_seq: 3, entry_data: constClass.PAYMENT_STATUS.REREG, entry_name: constClass.PAYMENT_STATUS_NAME.REREG },
        { input_type: 'radio', entry_type: 'payment_status', entry_seq: 4, entry_data: constClass.PAYMENT_STATUS.ERR, entry_name: constClass.PAYMENT_STATUS_NAME.ERR },
        { input_type: 'radio', entry_type: 'payment_status', entry_seq: 5, entry_data: constClass.PAYMENT_STATUS.CCL, entry_name: constClass.PAYMENT_STATUS_NAME.CCL },
      ]
    });
  }
  if (Common.getFunctionEnabled(setting, 'CANCEL')) {
    ret.push({ entry_type: 'cancel_date', entry_type_short_name: '退会日時', detail_data: [{ input_type: 'datetime', entry_type: 'cancel_date', entry_seq: 1 }] });
    ret.push({
      entry_type: 'cancel_cause', entry_type_short_name: '退会理由', detail_data: [
        { input_type: 'radio', entry_type: 'cancel_cause', entry_seq: 1, entry_data: constClass.CAUSE.BENEFITS, entry_name: constClass.CAUSE_NAME.BENEFITS },
        { input_type: 'radio', entry_type: 'cancel_cause', entry_seq: 2, entry_data: constClass.CAUSE.USAGE, entry_name: constClass.CAUSE_NAME.USAGE },
        { input_type: 'radio', entry_type: 'cancel_cause', entry_seq: 3, entry_data: constClass.CAUSE.NECESSARY, entry_name: constClass.CAUSE_NAME.NECESSARY },
        { input_type: 'radio', entry_type: 'cancel_cause', entry_seq: 4, entry_data: constClass.CAUSE.SIMILAR, entry_name: constClass.CAUSE_NAME.SIMILAR },
        { input_type: 'radio', entry_type: 'cancel_cause', entry_seq: 5, entry_data: constClass.CAUSE.OTHER, entry_name: constClass.CAUSE_NAME.OTHER },
      ]
    });
  }
  // 詳細の表示
  if (master) {
    master.forEach(m => {
      const detail = m.detail_data.find(() => true);
      if (detail.input_type === 'date') {
        if (detail.use_year === constClass.FLAG.ON) {
          ret.push({
            data: 't_customer_detail', entry_type: m.entry_type + '-y', entry_type_short_name: m.entry_type_short_name + '(年)', detail_data: [
              { input_type: 'date-y', entry_type: detail.entry_type, entry_seq: 1 }
            ]
          });
        }
        if (detail.use_month === constClass.FLAG.ON) {
          ret.push({
            data: 't_customer_detail', entry_type: m.entry_type + '-m', entry_type_short_name: m.entry_type_short_name + '(月)', detail_data: [
              { input_type: 'date-m', entry_type: detail.entry_type, entry_seq: 1 }
            ]
          });
        }
        if (detail.use_date === constClass.FLAG.ON) {
          ret.push({
            data: 't_customer_detail', entry_type: m.entry_type + '-d', entry_type_short_name: m.entry_type_short_name + '(日)', detail_data: [
              { input_type: 'date-d', entry_type: detail.entry_type, entry_seq: 1 }
            ]
          });
        }
      } else {
        ret.push({...m, data: 't_customer_detail'});
      }
    })
  }
  return ret;
}

Common.getCdFromQrString = function (str) {
  const qrstring = str.split('?');
  const qs = queryString.parse(qrstring.length > 1 ? qrstring[1] : qrstring[0]);
  return qs;
}

export default Common;