import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router';
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const Cashier = (props) => {
  const { siteId, shopId } = useParams();
  const [shop, setShop] = useState(null);
  const [cashierNew, setCashierNew] = useState({ shop_id: 0, shop_name: "", cashier_no: "", start_date: null, end_date: new Date('9999-12-31') });
  const [cashierData, setCashierData] = useState(null);
  const { addToast } = useToasts();
  const history = useHistory();

  const refreshCashier = useCallback(async () => {
    const jwt = localStorage.getItem('jwt');
    const getShop = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/${siteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      }
    })).data;
    const shopData = getShop.find(s => s.shop_id === Number(shopId));
    setShop(shopData || {});

    if (shopData) {
      const getCashier = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/shop/cashier/${siteId}/${shopId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })).data;
      // 日付型を変換
      const cashier = getCashier.map(s => ({ ...s, start_date: new Date(s.start_date), end_date: new Date(s.end_date) }));
      cashier.sort((a, b) => a.cashier_no < b.cashier_no ? -1 : 1);
      setCashierData(cashier);
      setCashierNew({ shop_id: "", cashier_no: "", start_date: new Date(shopData.start_date), end_date: new Date(shopData.end_date) });
    }
  }, [siteId, shopId])

  // value値変更イベント
  const handleChangeCell = (index, key) => (event) => {
    const target = event ? event.target : null;
    var value = target ? (target.type === "select" ? target.selected : target.value) : event; // valueが直接セットされる場合に対応
    if (index === 'new') {
      const new_data = { ...cashierNew, [key]: value };
      setCashierNew(new_data)
    } else {
      const _cashierData = [...cashierData]
      _cashierData[index] = { ..._cashierData[index], [key]: value };
      setCashierData(_cashierData)
    }
  }

  //更新ボタン押下時
  const editButtonClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 入力チェック
    if (data.cashier_no === "") {
      window.alert('レジNoを入力してください。');
      return;
    }
    if (data.cashier_no && data.cashier_no.length > 10) {
      window.alert('レジNoは10字以内で入力してください。');
      return;
    }
    if (!data.start_date) {
      window.alert('適用開始日を入力してください。');
      return;
    }
    if (!data.end_date) {
      window.alert('適用終了日を入力してください。');
      return;
    }
    if (data.start_date > data.end_date) {
      window.alert('適用開始日と終了日が逆転しています。');
      return;
    }

    // 確認メッセージ
    if (!window.confirm(`レジマスタ[${data.cashier_no}]を${data.site_id ? '更新' : '登録'}します。よろしいですか？`)) {
      return;
    }

    const params = {
      site_id: siteId,
      shop_id: shopId,
      cashier_no: data.cashier_no,
      start_date: data.start_date,
      end_date: data.end_date,
      upd_date: data.upd_date,
    };
    // const params = data;
    let res;
    try {
      if (data.site_id !== undefined) {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/cashier/update/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      } else {
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/cashier/insert/`, params, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('登録しました。', { appearance: 'success', autoDismiss: true });
        await refreshCashier();
      }
    }
  }

  //削除ボタン押下時
  const deleteButtonClick = async (data) => {
    const jwt = localStorage.getItem('jwt');

    // 確認メッセージ
    if (!window.confirm(`レジマスタ[${data.cashier_no}]を削除します。よろしいですか？`)) {
      return;
    }

    const params = {
      site_id: siteId,
      shop_id: shopId,
      cashier_no: data.cashier_no,
      start_date: data.start_date,
      end_date: data.end_date,
      upd_date: data.upd_date,
    };
    // const params = data;
    let res;
    try {
      res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/shop/cashier/delete/`, params, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });
    } catch (err) {
      console.log(err);
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });
      }
    } finally {
      console.log(res);
      if (res.data.error) {
        addToast(res.data.message, { appearance: 'error', autoDismiss: true });
      } else {
        addToast('削除しました。', { appearance: 'success', autoDismiss: true });
        await refreshCashier();
      }
    }
  }

  // 登録ボタン
  const registerButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-primary mx-1`}
        onClick={() => editButtonClick(data)}>
        {data.site_id ? '更新' : '登録'}
      </button>
    )
  }

  // 削除ボタン
  const deleteButton = (data) => {
    return (
      <button type="button"
        className={`btn btn-secondary mx-1`}
        onClick={() => deleteButtonClick(data)}>
        削除
      </button>
    )
  }

  useEffect(() => {
    refreshCashier();
  }, [refreshCashier]);

  return (
    <div className="container">

      {(shop === null && cashierData === null) && <div className="row"><div className="col-12">読み込み中・・・</div></div>}

      {(shop !== null && !shop.shop_id) &&
        <div className="row mb-3 p-0">
          <div className="col">
            <div className="row my-1">
              <div className="col-auto text-left">
                <button type="button"
                  className={`btn btn-secondary mx-1 w-100`}
                  onClick={() => history.goBack()}>
                  戻る
                </button>
              </div>
              <div className="col">
                <div className="row my-2">
                  不正な店舗IDが指定されました。
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {(shop !== null && shop.shop_id) &&
        <div className="row mb-3 p-0">
          <div className="col">
            <div className="row my-1">
              <div className="col-auto text-left">
                <button type="button"
                  className={`btn btn-secondary mx-1 w-100`}
                  onClick={() => history.goBack()}>
                  戻る
                </button>
              </div>
              <div className="col">
                <div className="row my-2">
                  {shop.shop_name} ({shop.shop_code})
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {(shop !== null && shop.shop_id && cashierData !== null) && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-info`}>
                <tr>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    レジNo
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    適用開始日
                  </td>
                  <td className="text-center">
                    <span className='needmark'>※</span>
                    適用終了日
                  </td>
                  <td className="text-center">
                    処理
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* 新規入力行 */}
                <tr>
                  {/* レジNo */}
                  <td className="text-center align-middle">
                    <input type="text" className="form-control" id={"cashier_no_new"} name="cashier_no" value={cashierNew.cashier_no || ""} onChange={handleChangeCell('new', 'cashier_no')}></input>
                  </td>
                  {/* 適用開始日 */}
                  <td className="text-center align-middle">
                    <DatePicker className="custom-select text-left"
                      locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'start_date_new'} name={'start_date'}
                      popperPlacement='bottom'
                      selected={cashierNew.start_date}
                      onChange={handleChangeCell('new', 'start_date')}
                      placeholderText="- 選択 -"
                      isClearable={false} />
                  </td>
                  {/* 適用終了日 */}
                  <td className="text-center align-middle">
                    <DatePicker className="custom-select text-left"
                      locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'end_date_new'} name={'end_date'}
                      popperPlacement='bottom'
                      selected={cashierNew.end_date}
                      onChange={handleChangeCell('new', 'end_date')}
                      placeholderText="- 選択 -"
                      isClearable={false} />
                  </td>
                  {/* 編集 */}
                  <td className="text-center align-middle">
                    {registerButton(cashierNew)}
                  </td>
                </tr>
                {cashierData.map((data, idx) => (
                  <tr key={data.cashier_no}>
                    {/* レジNo */}
                    <td className="text-center align-middle">
                      {data.cashier_no}
                    </td>
                    {/* 適用開始日 */}
                    <td className="text-center align-middle">
                      <DatePicker className="custom-select text-left"
                        locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'start_date' + idx} name={'start_date'}
                        popperPlacement='bottom'
                        selected={data.start_date}
                        onChange={handleChangeCell(idx, 'start_date')}
                        placeholderText="- 選択 -"
                        isClearable={false} />
                    </td>
                    {/* 適用終了日 */}
                    <td className="text-center align-middle">
                      <DatePicker className="custom-select text-left"
                        locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id={'end_date' + idx} name={'end_date'}
                        popperPlacement='bottom'
                        selected={data.end_date}
                        onChange={handleChangeCell(idx, 'end_date')}
                        placeholderText="- 選択 -"
                        isClearable={false} />
                    </td>
                    {/* 編集 */}
                    <td className="text-center align-middle table-cell">
                      {registerButton(data)}
                      {deleteButton(data)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Cashier