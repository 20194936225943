var Constants = {};

Constants.STATUS = { 
  UREG: 'UREG',
  REG: 'REG',
  CCL: 'CCL',
};

Constants.STATUS_NAME = { 
  UREG: '未登録',
  REG: '登録済み',
  CCL: '退会済み',
};

Constants.PAYMENT_STATUS = { 
  UPAY: 'UPAY',
  REG: 'REG',
  ERR: 'ERR',
  REREG: 'REREG',
  CCL: 'CCL',
};

Constants.PAYMENT_STATUS_NAME = { 
  UPAY: '決済未登録',
  REG: '決済登録済み',
  ERR: '決済失敗',
  REREG: '決済再登録済み',
  CCL: '決済停止中',
};

Constants.GENDER = {
  MAN: '1',
  WOMAN: '2',
  OTHER: '3',
}

Constants.GENDER_NAME = {
  '1': '男性',
  '2': '女性',
  '3': 'その他',
  MAN: '男性',
  WOMAN: '女性',
  OTHER: 'その他',
}

Constants.TICKET = {
  BUS: '1',
  TRAIN: '2',
  BOTH: '3',
  NONE: '4',
}

Constants.TICKET_NAME = {
  '1': 'バス',
  '2': '電車',
  '3': '両方',
  '4': '未保有',
  BUS: 'バス',
  TRAIN: '電車',
  BOTH: '両方',
  NONE: '未保有',
}

Constants.CAUSE = {
  BENEFITS: '1',
  USAGE: '2',
  NECESSARY: '3',
  SIMILAR: '4',
  OTHER: '5',
}

Constants.CAUSE_NAME = {
  '1': '魅力的特典がない',
  '2': '使い方が分からない',
  '3': '必要ではなくなった',
  '4': '他の類似サービスを利用するから',
  '5': 'その他',
  BENEFITS: '魅力的特典がない',
  USAGE: '使い方が分からない',
  NECESSARY: '必要ではなくなった',
  SIMILAR: '他の類似サービスを利用するから',
  OTHER: 'その他',
}

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.SAIBAN_CLASS = {
  CUSTOMER: 'CUSTOMER',
};

Constants.RECEIPT_NUM = {
};

Constants.SHOP_ID = 1;

Constants.MEMBERSHIPPASS = "membershippass";
Constants.COUPONLIST = "couponlist";
Constants.COUPON = "coupon";
Constants.USER = "user";
Constants.USERCHANGE = "userchange";
Constants.TICKETLIST = "ticketlist";
Constants.TICKETUSE = "ticketuse";
Constants.TICKETISSUEUSE = "ticketissueuse";
Constants.PAYMENT = "payment";

Constants.POINTLIST = "pointlist";
Constants.ENTRY = "entry";
//Constants.COUPON = "coupon";
//Constants.USER = "user";
Constants.NOTCAMPAIGN = "notcampaign";
Constants.CAMPAIGNLIST = "campaignlist";
Constants.CAMPAIGNCLOSE = "campaignclose";
Constants.CONFIRM = "confirm";
Constants.CONFIRM2 = "confirm2";
Constants.CAMPAIGNENTRY = "campaignentry";
Constants.RECEIPTSCAN = "receiptscan";

Constants.SETTING = {
  MEMBER_AMOUNT: "MEMBER_AMOUNT",
  NOT_MEMBER_AMOUNT: "NOT_MEMBER_AMOUNT",
  FIRST_MONTH: "FIRST_MONTH",
  PAYMENT_MESSAGE: "PAYMENT_MESSAGE",
  PAYMENT_MESSAGE2: "PAYMENT_MESSAGE2",
  PAYMENT_REQUIRED: "PAYMENT_REQUIRED",
  PAYMENT_BACK: "PAYMENT_BACK",
  MODAL_NOTICE: "MODAL_NOTICE",
  MODAL_NOTICE2: "MODAL_NOTICE2",
  LOGIN_FAIL_COUNT: "LOGIN_FAIL_COUNT",
  MFA_USE: "MFA_USE",
  DAY_BEFORE_LIMIT: "DAY_BEFORE_LIMIT",
}

Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.ACTIVE_BUTTONS = {
  'REG': [
    'PRE',
    'CALL',
  ],
  'PRE': [
    'FIN',
    'ABS',
  ],
  'AABS': [
    'FIN'
  ],
  'CALL': [
    'HOLD',
    'FIN',
  ],
  'HOLD': [
    'CALL',
  ],
  'ABS': [
    'FIN'
  ],
  'CCL': [],
  'UCCL': [],
  'ACCL': [],
  'FIN': [],
};

Constants.COLOR = {
  '1': 'info',
  '2': 'success',
  BUS: 'info',
  TRAIN: 'success',
};

Constants.FLAG = {
  OFF: '0',
  ON: '1'
}

Constants.INPUT = {
  DISABLED: '0',
  ENABLED: '1', 
  REQUIRED: '2'
}

Constants.RECEIVE_TIME = {
  AM: '1',
  PM12: '2',
  PM14: '3',
  PM16: '4',
}

Constants.RECEIVE_TIME_NAME = {
  '1': '午前中',
  '2': '12時～14時',
  '3': '14時～16時',
  '4': '16時～18時',
  AM: '午前中',
  PM12: '12時～14時',
  PM14: '14時～16時',
  PM16: '16時～18時',
}
Constants.CAMPAIGN_IMAGE_DIR="images/campaign/";
Constants.COUPON_IMAGE_DIR="images/coupon/";
Constants.COUPON_PDF_DIR="images/coupon_pdf/";
Constants.TICKET_IMAGE_DIR="images/ticket/";
Constants.TICKET_PDF_DIR="images/ticket_pdf/";

Constants.AUTH_ONLY = 2;

Constants.PRIVACYPOLICY_URL="https://www.nishitetsu.jp/nnr/policy/";
Constants.TERMS_OF_SERVICE_URL="https://www.nishitetsu.jp/bus/norikata/digitalcard/riyokiyaku/";
Constants.LP_URL="https://www.nishitetsu.ne.jp/nis/";

Constants.JOB_CD_NAME = {
  AUTH: '仮売上',
  SALES: '実売上',
  PEND: '保留',
  ERR: 'エラー',
  VOID: '取消',
}

Constants.USER_CLASS = {
  ADMIN: '1',
  OPERATOR: '2',
}

Constants.USER_CLASS_NAME = {
  '1': '管理者',
  '2': 'オペレーター',
  ADMIN: '管理者',
  OPERATOR: 'オペレーター',
};

Constants.CAMPAIGN_IMAGE_LIST = {
  CONFIRM_TITLE: 'confirm_title.png',
  POINTCARD: 'pointcard.png',
  POINTENTRY: 'point10.png',
  ENTRYCOMMENT: 'entry_comment.png',
  CAMPAIGNEND: 'campaign_end.png'
}

Constants.CAMPAIGN_IMAGE_LIST_NAME = {
  CONFIRM_TITLE: 'キャンペーン詳細表示タイトル',
  POINTCARD: 'ポイントカード表示画像',
  ENTRYCOMMENT: 'キャンペーン参加表示画像',
  CAMPAIGNEND: 'キャンペーン終了表示画像'
}

Constants.PUSH_STATUS={
  NOT: '0',
  SEND: '1'
}

Constants.CAMPAIGN_MESSAGE = {
  ENTRY_MESSAGE_1: "レスキュー達成！",
  ENTRY_MESSAGE_2: "本キャンペーンは何口でも応募可能です。\nひきつづき「フードロス削減」にご協力をお願いいたします。",
  END_MESSAGE_1: "「フードロス削減」にご協力いただき、ありがとうございました。\n※当選者の発表は、商品の発送をもってかえさせていただきます。",
  END_MESSAGE_2: "今後とも、「フードロス削減」にご協力をお願いいたします。", 
}

Constants.POINT_SCREEN = {
  MAIN: 1,
  CAMERA: 2,
  DETAIL: 3,
}

Constants.INVALID_CAUSE = {
  UNMATCH: '1',
  RET_RCPT: '2',
  AMOUNT: '3',
  RETURNED: '4',
  OTHER: '5',
}

Constants.INVALID_CAUSE_NAME = {
  '1': '突合エラー',
  '2': '返品レシート',
  '3': '金額不足',
  '4': '返品済み',
  '5': 'その他',
  UNMATCH: '突合エラー',
  RET_RCPT: '返品レシート',
  AMOUNT: '金額不足',
  RETURNED: '返品済み',
  OTHER: 'その他',
}

Constants.INVALID_FLAG = {
  UNCONFIRM: null,
  VALID: '0',
  INVALID: '1',
}

Constants.INVALID_FLAG_NAME = {
  '0': '有効',
  '1': '無効',
  UNCONFIRM: '未確認',
  VALID: '有効',
  INVALID: '無効',
}

export default Constants;