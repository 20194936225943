import constClass from '../../Constants/Constants';
import Common from '../Common/common';

const TicketList = ({ticket_list, ticketClick, siteId, setting}) => {

  const sysdate = new Date();
  const sysdateTimestamp = sysdate.getTime();
  
  return (
    <div id="ticketList" className="px-0-env">
      {ticket_list && ticket_list.map(ticket =>
        <div key={ticket.ticket_id} className="mx-3 mt-1 mb-3 bg-white border">
          <div className="row mx-0 px-3-env pt-2 pb-1 card-header">
            <div className="col py-1 px-1 align-self-center text-center">
              <h4 className="p-0 m-0">{ticket.ticket_name}</h4>
              {ticket.ticket_summary && <span className="p-0 m-0">{ticket.ticket_summary}</span>}
            </div>
          </div>
          {setting && sysdateTimestamp >= new Date(new Date(ticket.ticket_end_date).getTime() - setting[constClass.SETTING.DAY_BEFORE_LIMIT]*24*60*60*1000) && sysdateTimestamp <= new Date(ticket.ticket_end_date).getTime() &&
            <div className="row mx-0 px-3-env pt-2 pb-1 bg-incube">
              {ticket.ticket_image_url &&
                <div className="col-4 py-1 px-1 align-self-center text-center">
                  {!ticket.hp_url && <img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />}
                  {ticket.hp_url &&
                    <a href={Common.getExternalUrl(ticket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                      <img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />
                      <u>公式サイトへ</u>
                    </a>
                  }
                </div>
              }
              <div className="col py-1 px-1 align-self-center">
                <div className="row pb-1">
                  <div className="col">
                    <span className="p-0 m-0">{Common.getBrString(ticket.ticket_detail)}</span>
                  </div>
                </div>
                <div className="row pb-1">
                  <div className="col">
                    <span className="p-0 m-0 text-danger">有効期限 {Common.getDateObject1(ticket.ticket_end_date).str}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      onClick={ticketClick(ticket.ticket_id)}
                      className="btn btn-active w-100">
                      クーポンを表示する
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          {setting && (sysdateTimestamp <= new Date(new Date(ticket.ticket_end_date).getTime() - setting[constClass.SETTING.DAY_BEFORE_LIMIT]*24*60*60*1000) || sysdateTimestamp >= new Date(ticket.ticket_end_date).getTime()) &&
            <div className="row mx-0 px-3-env pt-2 pb-1">
              {ticket.ticket_image_url &&
                <div className="col-4 py-1 px-1 align-self-center text-center">
                  {!ticket.hp_url && <img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />}
                  {ticket.hp_url &&
                    <a href={Common.getExternalUrl(ticket.hp_url)} target="_blank" rel="noreferrer" className="text-digitalcard">
                      <img className="w-100" src={`/${siteId}/${constClass.TICKET_IMAGE_DIR}${ticket.ticket_image_url}`} alt={ticket.ticket_name} />
                      <u>公式サイトへ</u>
                    </a>
                  }
                </div>
              }
              <div className="col py-1 px-1 align-self-center">
                <div className="row pb-1">
                  <div className="col">
                    <span className="p-0 m-0">{Common.getBrString(ticket.ticket_detail)}</span>
                  </div>
                </div>
                <div className="row pb-1">
                  <div className="col">
                    <span className="p-0 m-0">有効期限 {Common.getDateObject1(ticket.ticket_end_date).str}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      onClick={ticketClick(ticket.ticket_id)}
                      className="btn btn-active w-100">
                      クーポンを表示する
                    </button>
                  </div>
                </div>
              </div>
            </div>
            }
        </div>
      )}
    </div>
  )
}

export default TicketList;