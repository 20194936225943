import React, { Component } from 'react';
import axios from 'axios';
import constClass from '../../Constants/Constants';
import Modal from 'react-modal';
import "react-accessible-accordion/dist/fancy-example.css";
import moment from 'moment';

Modal.setAppElement("#root");

class Liffconfirm extends Component {
  constructor(props) {
    super(props);
    const { campaign, setting } = this.props.condition;
    this.state = {
      campaign: campaign,
      setting: setting,
      page_items: null,
      siteId: isFinite(props.match.params.siteId) ? props.match.params.siteId : 0,
    };
  }
  componentDidMount() {
    this.refreshData();
  }
  
  async notEntryCheck() {
    if (window.liff.isInClient()) {
      window.liff.closeWindow();
    }
  }

  async refreshData() {

    const campaign = this.state.campaign;

    const pagePromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/campaignpage/line/${campaign.site_id}/${campaign.campaign_id}/`, { line_id: this.props.liff_access_token });
    const page_items = (await pagePromise).data;

    this.setState({
      campaign,
      page_items,
    });
  }

  renderConfirm() {
    return (
      <div className="bg-white overflow-auto">
        <div className="row mx-1 my-1">
          <div className="col py-0 px-0 align-self-center text-center">
            <img className="w-100" src={`/${this.state.campaign.site_id}/${constClass.CAMPAIGN_IMAGE_DIR}${this.state.campaign.campaign_id}/${constClass.CAMPAIGN_IMAGE_LIST.CONFIRM_TITLE}`}
              onError={e => e.target.style.display = 'none'}
              alt="title" />
          </div>
        </div>
        <div className="row mx-1 px-1-env pt-2 pb-1 ">
          <h4>{this.state.campaign ? this.state.campaign.campaign_name : ''}</h4>
        </div>
        <div className="row mx-1 pt-2 pb-1 ">
          <div className="col mx-0 px-1">
            {this.state.page_items && this.state.page_items.filter(x => Number(x.hidden_flag) !== Number(constClass.FLAG.ON)).map((data, idx) => (
              <div key={idx}>
                <h5>{data.header}</h5>
                <p className="pl-1" dangerouslySetInnerHTML={{__html: data.content}}></p>  
              </div>
            ))}
          </div>
        </div>
        {this.props.page === constClass.CONFIRM2 && this.state.campaign.entry_count !== 0 &&
          <button
          className="btn btn-link w-100 py-2"
          onClick={() => { this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.POINTLIST}&campaign_id=${this.state.campaign.campaign_id}`); }}>
          戻る
        </button>
        }
        {this.props.page === constClass.CONFIRM2 && this.state.campaign.entry_count === 0 &&
          <button
          className="btn btn-link w-100 py-2"
          onClick={() => { this.props.history.push(`/${this.state.campaign.site_id}?page=${constClass.CAMPAIGNLIST}`); }}>
          戻る
        </button>
        }
        {this.props.page !== constClass.CONFIRM2 &&
          <React.Fragment>
            {moment() > moment(this.state.campaign.campaign_end) &&
              <div className="row my-5 mx-1 pt-2 pb-1 ">
                <div className="col text-center">
                  <button className="btn btn-secondary w-100 py-2" onClick={() => this.notEntryCheck()}>本キャンペーンは終了しました。</button>
                </div>
              </div>
            }
            {moment() <= moment(this.state.campaign.campaign_end) &&
              <div className="row text-center m-2">
                <div className="col text-center">
                  <button className="btn-lg btn-info w-100 py-2" onClick={() => this.props.history.push(`/${this.state.siteId}?page=${constClass.CAMPAIGNENTRY}&campaign_id=${this.state.campaign.campaign_id}`)}>{this.state.setting['CAMPAIGN_ENTRY_BUTTON']}</button>
                  <button className="btn btn-link" onClick={() => this.notEntryCheck()}>参加しない</button>
                </div>
              </div>
            }
          </React.Fragment>
        }
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.campaign === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
        {this.state.campaign !== null &&
          <div>
            {this.renderConfirm()}
          </div>
        }
      </React.Fragment>
    )
  }
}

export default Liffconfirm;